import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { useEffect, useMemo, useState } from 'react';
import * as _ from 'lodash';
import { useQuery } from 'react-query';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { Typography } from '@material-ui/core';
import { useKit } from 'models/kits';
import { toGreeklish, toGreek } from 'greek-utils';
import useAPI from '../../../hooks/api-hooks';
import FormSection from '../atoms/FormSection';
import { useInputValidation } from '../../../hooks/form-rules';

const DoctorSearchForm = (props) => {
  const [ search, setSearch ] = useState('');

  const { isKitPGX } = useKit(props.kitId);

  const searchQuery = useMemo(() => {
    let defaultParams = {
      _limit: -1,
      _sort: 'family_name:asc,name:asc',
      verification_status: 'VERIFIED',
      _where: [
        { specialty_ncontains: 'ΨΥΧΙΑΤΡΙΚΗ' },
        { specialty_ncontains: 'ΝΕΥΡΟΛΟΓΙΑ' },
        { specialty_ncontains: 'ΠΑΙΔΟΨΥΧΙΑΤΡΙΚΗ' },
      ],
    };

    if (isKitPGX) {
      defaultParams = {
        _limit: -1,
        _sort: 'family_name:asc,name:asc',
        verification_status: 'VERIFIED',
        _where: [
          { _or: [
            [{ specialty_contains: 'ΨΥΧΙΑΤΡΙΚΗ' }],
            [{ specialty_contains: 'ΝΕΥΡΟΛΟΓΙΑ' }],
            [{ specialty_contains: 'ΠΑΙΔΟΨΥΧΙΑΤΡΙΚΗ' }],
          ] },
        ],
      };
    }

    const trimmedSearch = search.trim();

    if ('' === trimmedSearch) {
      return defaultParams;
    }

    const searchTokens = trimmedSearch
      .split(' ')
      .map((t) => { return t.trim(); })
      .filter((s) => { return '' !== s; });

    if (0 === searchTokens.length) {
      return defaultParams;
    }

    if (1 === searchTokens.length) {
      const [ f ] = searchTokens;

      let enF = '';
      let elF = '';

      if (f.match(/[a-z]/i)) {
        enF = f;
        elF = toGreek(f);
      } else {
        enF = toGreeklish(f);
        elF = f;
      }

      return {
        ...defaultParams,
        _where: [
          { _or: [
            [{ name_contains: enF }],
            [{ name_contains: elF }],

            [{ family_name_contains: enF }],
            [{ family_name_contains: elF }],

            [{ brand_name_contains: enF }],
            [{ brand_name_contains: elF }],
          ] },
          ...defaultParams?._where,
        ],
      };
    }

    if (2 === searchTokens.length) {
      // assume the user has written firstName and lastName
      const [ f, l ] = searchTokens;
      const all = searchTokens.join(' ');

      let enF = '';
      let elF = '';

      let enL = '';
      let elL = '';

      let enAll = '';
      let elAll = '';

      if (f.match(/[a-z]/i)) {
        enF = f;
        elF = toGreek(f);

        enL = l;
        elL = toGreek(l);

        enAll = all;
        elAll = toGreek(all);
      } else {
        enF = toGreeklish(f);
        elF = f;

        enL = toGreeklish(l);
        elL = l;

        enAll = toGreeklish(all);
        elAll = all;
      }

      return {
        ...defaultParams,
        _where: [
          { _or: [
            [{ name_contains: enF, family_name_contains: enL }],
            [{ name_contains: elF, family_name_contains: elL }],

            [{ name_contains: enL, family_name_contains: enF }],
            [{ name_contains: elL, family_name_contains: elF }],

            [{ brand_name_contains: enAll }],
            [{ brand_name_contains: elAll }],
          ] },
          ...defaultParams?._where,
        ],
      };
    }

    if (2 < searchTokens.length) {
      const all = searchTokens.join(' ');

      let enAll = '';
      let elAll = '';

      if (all.match(/[a-z]/i)) {
        enAll = all;
        elAll = toGreek(all);
      } else {
        enAll = toGreeklish(all);
        elAll = all;
      }

      return {
        ...defaultParams,
        _where: [
          { _or: [
            [{ brand_name_contains: enAll }],
            [{ brand_name_contains: elAll }],
          ] },
          ...defaultParams?._where,
        ],
      };
    }

    return {
      _limit: 0,
    };
  }, [ search, isKitPGX ]);

  const { client } = useAPI();

  const {
    data: options,
    isLoading,
    isFetching,
  } = useQuery(
    [ 'doctors', { search, searchQuery, country: props.country }],
    () => {
      if (props.country) {
        return client.get('doctors', { params: { ...searchQuery, country_eq: props.country } });
      }

      return client.get('doctors', { params: { ...searchQuery } });
    },
  );

  const loading = isLoading || isFetching;

  const [ value, setValue ] = useState(null);

  useEffect(() => {
    if (props?.doctorSearchFormValue && !value) {
      setValue(props?.doctorSearchFormValue);
    }
  }, [ props?.doctorSearchFormValue ]);

  const { t } = useTranslation();

  const [
    errorSelectDoctor,
    helperTextSelectDoctor,
    validateSelectDoctor,
  ] = useInputValidation(
    [
      () => { return !!value || t('You must select a doctor'); },
    ],
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    if (![
      () => { return validateSelectDoctor(value); },
    ].every((f) => { return true === f(); })) {
      // return onError();
      console.debug('sdcs');
    }
  };

  const error = errorSelectDoctor && !value;

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
      style={ {
        paddingLeft: '24px',
        paddingRight: '24px',
        paddingTop: '6px',
        paddingBottom: '6px',
      } }
    >
      <FormSection label={ props.title }>
        {
          props.subtitle && (
            <Typography>
              {props.subtitle}
            </Typography>
          )
        }
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 12 }>
                <Autocomplete
                  filterOptions={ (opts) => {
                    return opts;
                  } }
                  getOptionSelected={ (option, v) => {
                    return option.id === v.id;
                  } }
                  renderOption={ (option) => {
                    return (
                      <Grid container>
                        <Grid item xs={ 12 }>
                          <Typography variant='subtitle1'>
                            { `${ option.family_name }, ${ option.name }` }
                          </Typography>
                        </Grid>
                        {
                          null != option.brand_name && '' !== option.brand_name
                        && (
                          <Grid item xs={ 12 }>
                            <Typography variant='subtitle1'>
                              { `${ t('common.company_name') }: ${ option.brand_name }` }
                            </Typography>
                          </Grid>
                        )
                        }
                        <Grid item xs={ 12 }>
                          <Typography variant='subtitle1'>
                            { `${ t('common.specialty') }: ${ t(`common.specialties.${ option.specialty }`) }` }
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  } }
                  getOptionLabel={ (option) => {
                    return `${ option.family_name } ${ option.name }` || '';
                  } }
                  options={ options || [] }
                  value={ value }
                  loading={ loading }
                  onChange={ (event, newValue) => {
                    const copy = _.cloneDeep(newValue);
                    if (null != copy && null == copy.brand_name) {
                      copy.brand_name = '';
                    }

                    setValue(copy);
                    props.func(copy);
                  } }
                  inputValue={ search }
                  inputProps={ { style: { textTransform: 'uppercase' } } }
                  onInputChange={ (e, newValue, reason) => {
                    if ('' === newValue && 'reset' === reason) {
                      return;
                    }
                    setSearch(newValue?.toUpperCase());
                  } }
                  renderInput={ (params) => {
                    return (
                      <Grid container>
                        <TextField
                          variant='outlined'
                          color='secondary'
                          size='small'
                          margin='dense'
                          error={ error || props.pgxError }
                          helperText={ error ? helperTextSelectDoctor : props.pgxError ? t('You must select a doctor') : '' }
                          { ...params }
                        />
                      </Grid>
                    );
                  } }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>
    </form>
  );
};

export default DoctorSearchForm;
/*
{ null === value ? (
) : (
          <Grid item xs={ 12 } style={ { marginTop: '12opx', marginBottom: '24px' } }>
            <a
              href='/cancel'
              onClick={ (e) => { e.preventDefault(); setValue(null); } }
            >
              {t('Πίσω στην αναζήτηση')}
            </a>
          </Grid>
        ) }

        {null !== value && (
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('firstName') }
                    defaultValue={ value.name }
                    readOnly
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('lastName') }
                    defaultValue={ value.family_name }
                    readOnly
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={ 12 }>
              <SimpleInputField
                label={ t('email') }
                defaultValue={ value.email }
                readOnly
              />
            </Grid>
            <Grid item xs={ 12 }>
              <SimpleInputField
                label={ t('specialty') }
                defaultValue={ value.specialty }
                readOnly
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                label={ t('mobileNumber') }
                defaultValue={ value.phone_number || '' }
                readOnly
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                label={ t('businessNumber') }
                defaultValue={ value.business_number || '' }
                readOnly
              />
            </Grid>
          </Grid>
        ) }

        {null !== value && (
        <FormSectionSubmit
          label={ t('save') }
        />
      ) }

*/
