import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useMemo } from 'react';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { useTranslation } from 'react-i18next';
import { langTypeToText } from 'components/app/LangSwitcher';
import useAPI from '../hooks/api-hooks';
import { InvitationReason } from './invitations';
import CollectionKeys from './atoms/collection-keys';

export const KITS_KEY = CollectionKeys.Kits;

export const useKits = (refetching = 0) => {
  const { client } = useAPI();

  const q = useQuery(
    [ KITS_KEY, refetching ],
    () => {
      return client.get('registration-kits', {
        params: {
          _limit: -1,
        },
      });
    },
    {
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnWindowFocus: false,
    },
  );

  const kitsPending = useMemo(() => {
    return q.data?.filter((kit) => {
      return 'sample_pending' === kit.registrationStatus;
    });
  }, [ q.data ]);

  const existsCompleteKitRegistration = useMemo(() => {
    if (q.data === undefined) {
      return undefined;
    }
    return 0 < q.data.filter((kit) => {
      return 'sample_pending' === kit.registrationStatus || 'not_purchased' === kit.registrationStatus
      || 'registration_done' === kit.registrationStatus || 'sample_received' === kit.registrationStatus || 'analysis' === kit.registrationStatus
      || 'done' === kit.registrationStatus;
    }).length;
  }, [ q.data ]);

  return {
    ...q,
    kitsPending,
    existsCompleteKitRegistration,
  };
};

export const useKits1 = () => {
  const { client } = useAPI();

  const q = useQuery(
    [ KITS_KEY ],
    () => {
      return client.get('registration-kits', {
        params: {
          _limit: -1,
        },
      });
    },
  );

  const kitsPending = useMemo(() => {
    return q.data?.filter((kit) => {
      return 'sample_pending' === kit.registrationStatus && null === kit?.serial_number_id?.ancestor_id;
    });
  }, [ q.data ]);

  const existsCompleteKitRegistration = useMemo(() => {
    if (q.data === undefined) {
      return undefined;
    }
    return 0 < q.data.filter((kit) => {
      return 'sample_pending' === kit.registrationStatus || 'not_purchased' === kit.registrationStatus
      || 'registration_done' === kit.registrationStatus || 'sample_received' === kit.registrationStatus || 'analysis' === kit.registrationStatus
      || 'done' === kit.registrationStatus;
    }).length;
  }, [ q.data ]);

  return {
    ...q,
    kitsPending,
    existsCompleteKitRegistration,
  };
};

export const useDynamicReportsData = (_lang = 'el-GR') => {
  const { client } = useAPI();

  const { serial } = useOptionSerial('3-in-1');
  const { navigateToPage } = useNavigation();

  if (serial === undefined) {
    navigateToPage({ pageId: 'general' });
  }

  const {
    data: thematicPackages,
  } = useQuery(
    [ 'dynamic-reports', { serial }],
    () => {
      if (serial !== undefined) {
        return client.get(`dynamic-reports/${ serial }`);
      }
      return null;
    },
  );

  return thematicPackages;
};

export const useNutritionData = (_lang = 'el-GR') => {
  const { client } = useAPI();

  const { serial } = useOptionSerial('nutrition');

  const { navigateToPage } = useNavigation();

  if (serial === undefined) {
    navigateToPage({ pageId: 'general' });
  }

  const {
    data: thematicPackages,
  } = useQuery(
    [ 'nutrition', { serial }],
    () => {
      if (serial !== undefined) {
        return client.get(`dynamic-reports/${ serial }`);
      }
      return null;
    },
  );

  return thematicPackages;
};

export const useSportsData = (_lang = 'el-GR') => {
  const { client } = useAPI();

  const { serial } = useOptionSerial('sports');

  const {
    data: thematicPackages,
  } = useQuery(
    [ 'sports', { serial }],
    () => {
      return client.get(`dynamic-reports/${ serial }`);
    },
  );

  return thematicPackages;
};

export const useBioAgeData = (_lang = 'el-GR') => {
  const { client } = useAPI();

  const { serial } = useOptionSerial('bioAge');

  const {
    data: thematicPackages,
  } = useQuery(
    [ 'bio-age', { serial }],
    () => {
      return client.get(`dynamic-reports/${ serial }`);
    },
  );

  return thematicPackages;
};

export const useVitaminsData = (_lang = 'el-GR') => {
  const { client } = useAPI();

  const { serial } = useOptionSerial('vitamins');

  const {
    data: thematicPackages,
  } = useQuery(
    [ 'vitamins', { serial }],
    () => {
      return client.get(`dynamic-reports/${ serial }`);
    },
  );

  return thematicPackages;
};

export const useDataReport = (kitId, names) => {
  const { client } = useAPI();

  const { data: kits } = useKits();
  const { navigateToPage } = useNavigation();

  const cardioReportKit = useMemo(() => {
    return kits?.find((kit) => {
      return names.includes(kit.thematic_package.thematic_category_id.name);
    });
  }, [ kits ]);

  const selectedKitLocale = useMemo(() => {
    const loc = kits?.find((kit) => {
      return kit.serial_number_id.serial === kitId;
    })?.locale ?? '2';

    return langTypeToText[loc];
  }, [ kits, kitId ]);

  let serial = kitId;

  if (null == kitId || '' === kitId) {
    serial = cardioReportKit?.serial_number_id.serial;
  }

  if (serial === undefined) {
    navigateToPage({ pageId: 'general' });
  }

  const {
    data,
  } = useQuery(
    [ 'cardio-reports', { kitId }, selectedKitLocale ],
    () => {
      if (kitId !== undefined) {
        return client.get(`dynamic-reports/${ serial }?locale.name=${ selectedKitLocale }`);
      }
      return undefined;
    },
  );

  return { data, locale: selectedKitLocale };
};

export const useNutritionDataReport = (kitId, _lang = 'el-GR') => {
  return useDataReport(kitId, [ 'Nutrition' ]);
};

export const useBioAgeDataReport = (kitId, _lang = 'el-GR') => {
  return useDataReport(kitId, [ 'Biological Age' ]);
};

export const use3ToOneDataReport = (kitId, _lang = 'el-GR') => {
  return useDataReport(kitId, [ '3-in-1' ]);
};

export const usePgxDataReport = (kitId, _lang = 'el-GR') => {
  return useDataReport(kitId, [ 'PGx' ]);
};

export const useCardioDataReport = (kitId, _lang = 'el-GR') => {
  return useDataReport(kitId, [ 'IDNA Cardio Health', 'Cardio Health' ]);
};

export const useSerialNumberRegistration = () => {
  const { t } = useTranslation();

  const { client } = useAPI({
    codeAlertSuccess: t('The serial number is successfully registered to your account'),
  });

  const queryClient = useQueryClient();

  const m = useMutation(
    'KIT-REGISTRATION',
    ({ serialNumber, registrationType, isChildrenRegistration, ancestorId }) => {
      return client.post('registration-kits/correlate',
        { serialNumber,
          isChildrenRegistration: isChildrenRegistration ?? false,
          ancestor_id: ancestorId,
          ...null != registrationType ? { registrationType } : {} });
    },
    {
      onSettled() {
        queryClient.invalidateQueries([ 'registration-kits', 0 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 0 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 1 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 'COUNT' ]);
        return queryClient.invalidateQueries(KITS_KEY);
      },
    },
  );

  return m;
};

export const useKit = (kitId) => {
  const { client } = useAPI();
  const queryClient = useQueryClient();

  const q = useQuery(
    [ KITS_KEY, { kitId: `${ kitId }` }],
    () => {
      if (kitId !== undefined) {
        return client.get(`registration-kits/${ kitId }`);
      }
      return undefined;
    },
  );

  const serial = q.data?.serial_number_id?.serial;

  const categoryId = q.data?.thematic_package?.thematic_category_id?.name;

  const child = q.data?.child_id;

  const isKitPGX = 'PGx' === categoryId;// serial?.toLowerCase().startsWith('id'.toLowerCase());

  const isKitWellness = '3-in-1' === categoryId || 'Vitamins' === categoryId || 'Sports' === categoryId || 'Nutrition' === categoryId;

  const isKit3InOne = '3-in-1' === categoryId;
  const isKitVitamins = 'Vitamins' === categoryId;
  const isKitSports = 'Sports' === categoryId;
  const isKitNutrition = 'Nutrition' === categoryId;

  const isRepeat = null !== q.data?.serial_number_id?.ancestor;

  // serial?.toLowerCase().startsWith('wl'.toLowerCase());

  const isKitCvd = 'IDNA Cardio Health' === categoryId || 'Cardio Health' === categoryId;
  // serial?.toLowerCase().startsWith('ch'.toLowerCase());

  const isKitNutri = 'IDNA Cardio Health' === categoryId || 'Cardio Health' === categoryId || 'NutriGenetix' === categoryId || 'NutriGenetix-Laboratories' === categoryId;
  // serial?.toLowerCase().startsWith('ng'.toLowerCase()) || serial?.toLowerCase().startsWith('ch'.toLowerCase());

  const isKitOnlyNutri = 'NutriGenetix' === categoryId || 'NutriGenetix-Laboratories' === categoryId;
  const isKitOnlyNutriLabs = 'NutriGenetix-Laboratories' === categoryId;

  const isKitBioAge = 'Biological Age' === categoryId;
  // serial?.toLowerCase().startsWith('bi'.toLowerCase());

  const isKitLabs = isKitCvd || isKitOnlyNutriLabs || isKitPGX;

  const questionnaireFormId = q.data?.thematic_package?.questionnaire_id?.form_id;

  const locale = q.data?.locale;

  // const cost = q.data?.thematic_package?.report_price + q.data?.thematic_package?.kit_price;
  let cost = 0;
  if (q.data && 'paid' !== q.data.serial_number_id.report_payment_status && 'free' !== q.data.serial_number_id.report_payment_status) {
    cost += q.data.thematic_package?.report_price ?? 0;
  }
  if (q.data && 'paid' !== q.data.serial_number_id.kit_payment_status && 'free' !== q.data.serial_number_id.kit_payment_status) {
    cost += q.data.thematic_package?.kit_price ?? 0;
  }

  const kitMetadata = useMemo(() => {
    return q.data?.metadata;
  }, [ q.data ]);

  const sampleCollectionDate = useMemo(() => {
    return q.data?.metadata?.sampleCollectionDate;
  }, [ q.data ]);

  const guardian = useMemo(() => {
    return q.data?.metadata?.guardian;
  }, [ q.data ]);

  const kitAgreements = useMemo(() => {
    return q.data?.agreements.reduce((acc, agreement) => {
      return {
        ...acc,
        [agreement.consent.name]: agreement.status,
      };
    }, {});
  }, [ q.data ]);

  const kitDoctorInvitations = useMemo(() => {
    return q.data?.invitations.filter((inv) => {
      return inv.reason === InvitationReason.PGX_DOCTOR_CONSENT || inv.reason === InvitationReason.HEALTHCARE_PROFESSIONAL_SHARE_RESULTS;
    });
  }, [ q.data ]);

  const kitHealthcareProfessionalInvitations = useMemo(() => {
    return q.data?.invitations.filter((inv) => {
      return inv.reason === InvitationReason.HEALTHCARE_PROFESSIONAL_SHARE_RESULTS;
    });
  }, [ q.data ]);

  const questions = useMemo(() => {
    if (null != child) {
      return q.data?.thematic_package?.questionnaire_id?.questions_child;
    }

    if (null != q.data?.doctor) {
      return q.data?.thematic_package?.questionnaire_id?.questions_doctor;
    }

    return q.data?.thematic_package?.questionnaire_id?.questions;
  }, [ q.data ]);

  const { mutateAsync: upsertAgreement } = useMutation(
    'upsert-agreement',
    ({ consentName, status }) => {
      return client.post('agreements',
        {
          registration_kit_id: kitId,
          consent_name: consentName,
          status,
        });
    },
    {
      onSettled() {
        return queryClient.invalidateQueries([ KITS_KEY, { kitId: `${ kitId }` }]);
      },
    },
  );

  const { mutateAsync: deleteAgreement } = useMutation(
    'delete-agreement',
    ({ consentName }) => {
      const agreement = q.data?.agreements
        .find((a) => {
          return a?.consent?.name === consentName;
        });
      if (!agreement || !agreement.id) {
        return null;
      }
      return client.delete(`agreements/${ agreement.id }`);
    },
    {
      onSettled() {
        return queryClient.invalidateQueries([ KITS_KEY, { kitId: `${ kitId }` }]);
      },
    },
  );

  const { mutateAsync: updateLocale } = useMutation(
    'update-locale',
    ({ newLocale }) => {
      return client.put(`registration-kits/${ kitId }`, {
        locale: newLocale,
      });
    },
    {
      onSettled() {
        return queryClient.invalidateQueries([ KITS_KEY, { kitId: `${ kitId }` }]);
      },
    },
  );

  const { mutateAsync: updateKitMetadata } = useMutation(
    'update-kit-metadata',
    (
      {
        metadata,
        registrationStatus,
      },
    ) => {
      const met = {
        ...kitMetadata,
        ...metadata,
      };

      if (null != metadata) {
        if ('' === metadata.address) {
          met.address = '';
          met.postalCode = '';
          met.city = '';
        } else if ('' === metadata.name) {
          met.name = '';
          met.family_name = '';
          met.father_name = '';
        }
      }

      return client.put(`registration-kits/${ kitId }`, {
        metadata: met,
        registrationStatus,
      });
    },
    {
      onSettled() {
        queryClient.invalidateQueries([ 'registration-kits', 0 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 0 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 1 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 'COUNT' ]);
        return queryClient.invalidateQueries([ KITS_KEY, { kitId: `${ kitId }` }]);
      },
    },
  );

  const { mutateAsync: bioAgeQuestionaireBlood } = useMutation(
    'bio-age-questions', (body) => {
      return client.put(`/customer-questionnaire-answers/${ serial }`, body);
    },
  );

  const { mutateAsync: setFormGuardian } = useMutation(
    'formGuardian', (body) => {
      const met = {
        ...kitMetadata,
        guardian: body,
      };

      return client.put(`/registration-kits/metadata-update/${ serial }`,
        met);
    },
  );

  const q1 = useQuery(
    [ 'Questionnaire-key', serial ],
    () => {
      return client.get(`/customer-questionnaire-answers?serial_number.serial=${ serial }`);
    },
  );

  const questiondata = q1?.data;// an einai adeios o pinakas deixneis to questionnaire, an einai gematos den deixneis to questionnaire

  return {
    ...q,
    questiondata,
    serial,
    isKitPGX,
    isKitWellness,
    isKitOnlyNutriLabs,
    isKitCvd,
    isKitNutri,
    isKitBioAge,
    isKitLabs,
    isKitOnlyNutri,
    kitAgreements,
    deleteAgreement,
    guardian,
    upsertAgreement,
    kitDoctorInvitations,
    kitHealthcareProfessionalInvitations,
    updateKitMetadata,
    bioAgeQuestionaireBlood,
    setFormGuardian,
    sampleCollectionDate,
    questionnaireFormId,
    questions,
    categoryId,
    kitMetadata,
    updateLocale,
    locale,
    isKit3InOne,
    isKitVitamins,
    isKitSports,
    isKitNutrition,
    child,
    cost,
    isRepeat,
  };
};
