/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useAuth } from 'AuthCtx';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Container, Dialog, makeStyles, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { FilePDFViewer } from 'components/PDFViewer';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import SimpleDateField from '../atoms/SimpleDateField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSection from '../atoms/FormSection';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import { useKit } from '../../../models/kits';
// import cottonSwabs from '../../../assets/cotton_swabs.svg';
// import sendSample from '../../../assets/send_paper.svg';
/*
const CottonSwabs = () => {
  return (
    <img
      alt='follow-instructions'
      width='20px'
      height='auto'
      src={ cottonSwabs }
    />
  );
};

const SendSample = () => {
  return (
    <img
      alt='send-sample'
      width='20px'
      color='rgb(148, 195, 32)'
      height='auto'
      src={ sendSample }
    />
  );
}; */

const useStyles = makeStyles((theme) => {
  return {
    root: {
      fontSize: '17px',
      fontFamily: 'Roboto',
      lineHeight: '21px',
      letterSpacing: '.04em',
      color: theme.palette.secondary.main,
      textAlign: 'initial',
    },
    alertStyle: {
      '& .MuiAlert-message': {
        width: '100%',
      },
    },
    styleforsmalltext: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
    },
    nonboldtext: {
      color: theme.palette.secondary.main,
    },
    blackboldtext: {
      color: '#767171',
      fontWeight: 'bold',
    },
    blacktext: {
      color: '#767171',
    },
    filtergreen: {
      filter: 'invert(61%) sepia(72%) saturate(482%) hue-rotate(36deg) brightness(101%) contrast(84%)',
    },
    filterblue: {
      filter: 'invert(59%) sepia(18%) saturate(815%) hue-rotate(165deg) brightness(86%) contrast(87%)',
    },
  };
});

const SampleCollectionForm = (
  {
    kitId,
    onSubmit,
    onError,
    goToPrevPage,
  },
) => {
  const classes = useStyles();

  const [ openPdfDialog, setOpenPdfDialog ] = useState(false);

  const { t, i18n } = useTranslation();

  const { isLoading, updateKitMetadata, sampleCollectionDate, data, isKitCvd, isKit3InOne, isKitNutrition,
    isKitSports, isKitVitamins, isKitPGX, isKitOnlyNutri, isKitOnlyNutriLabs } = useKit(kitId);

  const { isDoctor } = useAuth();

  const [ isSubmitting, setSubmitting ] = useState(false);

  const country = data?.customer_id?.country;

  const {
    dateRule,
  } = useFormRules();

  const [
    errorDate,
    helperTextDate,
    validateDate,
  ] = useInputValidation(
    [
      dateRule,
    ],
  );

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      sampleCollectionDate: dateOfSampleCollection,
    } = Object.fromEntries(formData);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateDate(dateOfSampleCollection); },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        return updateKitMetadata({
          metadata: {
            sampleCollectionDate: dateOfSampleCollection,
          },
        });
      })
      .then(onSubmit)
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  const now = new Date();
  const dateString = sampleCollectionDate || `${ now.getFullYear() }-${ (`0${ now.getMonth() + 1 }`).slice(-2) }-${ (`0${ now.getDate() }`).slice(-2) }`;

  let path = '/Collect sample instructions_CVD.pdf';

  let productName = 'cardio';

  if (isKitCvd) {
    productName = 'cardio';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_CVD.pdf' : 'assets/pdfs/CVDInstructionsEL.pdf';
  } else if (isKitOnlyNutriLabs) {
    productName = 'vitamines';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_NutriGenetix-Labs.pdf' : 'assets/pdfs/Διαδικασία λήψης δείγματος NutriGenetix-Labs.pdf';
  } else if (isKitOnlyNutri) {
    productName = 'vitamines';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_NutriGenetix.pdf' : 'assets/pdfs/Διαδικασία λήψης δείγματος NutriGenetix.pdf';
  } else if (isKitVitamins) {
    productName = 'vitamines';
    path = 'en-US' === i18n.language ? 'assets/pdfs/WellnessInstructionsEN.pdf' : 'assets/pdfs/WellnessInstructionsEL.pdf';
  } else if (isKitNutrition) {
    productName = 'diatrofi';
    path = 'en-US' === i18n.language ? 'assets/pdfs/WellnessInstructionsEN.pdf' : 'assets/pdfs/WellnessInstructionsEL.pdf';
  } else if (isKitSports) {
    productName = 'athlisi';
    path = 'en-US' === i18n.language ? 'assets/pdfs/WellnessInstructionsEN.pdf' : 'assets/pdfs/WellnessInstructionsEL.pdf';
  } else if (isKit3InOne) {
    productName = '3-se-1';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_Wellness.pdf' : 'assets/pdfs/WellnessInstructionsEL.pdf';
  } else if (isKitPGX) {
    productName = 'pgx';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_PGx.pdf'
      : '/assets/pdfs/Διαδικασία λήψης δείγματος_PGx (1).pdf';
  }

  let extraFieldInAddress = '';
  if ('en-US' === i18n.language) {
    extraFieldInAddress = '/en';
  }

  /* Splitting strings to style them seperately */
  const collectSampleInfo = t('reg_kit.sample.doctor.collect_sample_info');

  const collectSampleInfoArr = collectSampleInfo.split(t('common.here'));

  const sendSampleInfo = t('reg_kit.sample.doctor.send_to_idna');
  let sendSampleInfoArr = sendSampleInfo.split('δειγματοληψίας');
  if ('el-GR' === i18n.language) { sendSampleInfoArr[0] += 'δειγματοληψίας'; }
  if ('en-US' === i18n.language) {
    sendSampleInfoArr = sendSampleInfo.split('box,');
    sendSampleInfoArr[0] += 'box,';
  }

  let results;
  let resultsArrFirstHalf;
  let resultsArrSecondHalf;
  if (!isKitPGX && isDoctor) { results = t('reg_kit.results_receipt.doctor.non_pgx_non_bioage.report_availiable'); }
  if (!isKitPGX && !isDoctor) { results = t('reg_kit.results_receipt.adult.non_pgx_non_bioage.report_availiable'); }
  if (isKitPGX && isDoctor) { results = t('resultReceiptContentDoctor'); }
  if (isKitPGX && !isDoctor) { results = t('resultReceiptContentCustomer'); }

  const resultsArr = results.split('ημερών');
  if ('el-GR' === i18n.language) {
    resultsArr[0] += 'ημερών';
  }
  if ('en-US' === i18n.language) {
    resultsArrFirstHalf = results.split('within');
    resultsArrFirstHalf[0] += 'within';
    resultsArrSecondHalf = resultsArrFirstHalf[1].split('days');
    resultsArrSecondHalf[0] += 'days';
  }

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Container style={ { padding: 0 } }>
        <Dialog
          open={ openPdfDialog }
          onClose={ () => { setOpenPdfDialog(false); } }
          fullWidth
          maxWidth='lg'
        >
          <div style={ { padding: '30px', borderRadius: '15px', position: 'relative' } }>
            <IconButton
              style={ { position: 'absolute', top: '5px', right: '5px', padding: 0, margin: 0 } }
              onClick={ () => { setOpenPdfDialog(false); } }
            >
              <CancelIcon style={ { color: 'red' } } />
            </IconButton>
            <FilePDFViewer path={ path } />
          </div>
        </Dialog>
        <form
          noValidate
          onSubmit={ handleSubmit }
        >
          <Grid
            container
            style={ {
              padding: '5px',
            } }
          >
            <FormSection label={ t('reg_kit.sample.common.sample_date_title') }>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleDateField
                    label={ t('reg_kit.sample.common.sample_collection_date') }
                    defaultValue={ dateString }
                    name='sampleCollectionDate'
                    required
                    error={ errorDate }
                    helperText={ helperTextDate }
                    country={ country }
                  />
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
          <Grid
            container
            style={ {
              margin: '-40px',
              marginLeft: '0px',
              marginBottom: '0px',
            } }
            sx={ {
              borderRadius: '10px',
            } }
          >
            <FormSectionSubmit
              label={ t('common.save') }
              loading={ isSubmitting }
              goToPrevPage={ goToPrevPage }
              color={ isDoctor ? '#526D9C' : undefined }
            />
            <Alert
              severity='info'
              style={ {
                marginTop: '15px',
                textAlign: 'initial',
                width: '100%',
                border: '1px',
                borderRadius: '30px',
              } }
              className={ classes.alertStyle }
              icon={ (
                <Grid />
              ) }
            >
              <div style={ { marginLeft: '-15px', padding: '10px' } }>
                <div style={ { display: 'flex', flexDirection: 'row', gap: '16px' } }>
                  <AlertTitle className={ classes.root }>
                    <InfoOutlinedIcon style={ { fontSize: '35px' } } />
                  </AlertTitle>

                  <div style={ { marginLeft: '-10px', lineHeight: '0.6' } }>
                    <AlertTitle className={ classes.root }>
                      {t('reg_kit.sample.common.collect_sample_title')}
                    </AlertTitle>

                    <div className={ isDoctor ? classes.styleforsmalltext : classes.blackboldtext }>
                      { collectSampleInfoArr[0] }
                      <a
                        href=''
                        onClick={ (e) => {
                          e.preventDefault();
                          setOpenPdfDialog(true);
                        } }
                        className={ isDoctor ? classes.inherit : classes.blackboldtext }
                      >
                        {t('common.here')}
                      </a>
                      { collectSampleInfoArr[1] }
                    </div>
                  </div>
                </div>

                <div style={ { display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '10px' } }>
                  <AlertTitle>
                    <img
                      src='/deliveryicon.svg'
                      alt='delivery-icon'
                      className={ isDoctor ? classes.filterblue : classes.filtergreen }
                      style={ { width: '35px', height: '35px', marginTop: '10px' } }
                    />
                  </AlertTitle>

                  <div style={ { marginLeft: '-10px', lineHeight: '1.3', marginTop: '10px' } }>
                    <AlertTitle className={ classes.root } style={ { lineHeight: '0.6' } }>
                      {t('reg_kit.sample.common.send_sample_title')}
                    </AlertTitle>

                    <div>
                      {
                        isDoctor
                          ? (
                            <>
                              <span className={ classes.styleforsmalltext }>{sendSampleInfoArr[0]}</span>
                              <span className={ classes.nonboldtext }>{sendSampleInfoArr[1]}</span>
                            </>
                          )
                          : (
                            <>
                              <span className={ classes.blackboldtext }>{sendSampleInfoArr[0]}</span>
                              <span className={ classes.blacktext }>{sendSampleInfoArr[1]}</span>
                            </>
                          )
                      }
                    </div>
                  </div>
                </div>

                <div style={ { display: 'flex', flexDirection: 'row', gap: '16px', marginTop: '10px' } }>
                  <AlertTitle className={ classes.root } style={ { marginTop: '15px' } }>
                    <AssignmentOutlinedIcon style={ { fontSize: '35px' } } />
                  </AlertTitle>

                  <div style={ { marginLeft: '-10px', lineHeight: '1.3' } }>
                    <AlertTitle
                      className={ classes.root }
                      style={ {
                        marginTop: '15px',
                        lineHeight: '0.6',
                      } }
                    >
                      {t('reg_kit.results_receipt.common.title')}
                    </AlertTitle>

                    {'el-GR' === i18n.language && (
                      <>
                        <span className={ isDoctor ? classes.styleforsmalltext : classes.blackboldtext }>
                          {resultsArr[0]}
                        </span>
                        <span className={ isDoctor ? classes.nonboldtext : classes.blacktext }>
                          {resultsArr[1]}
                        </span>
                      </>
                    )}

                    {'en-US' === i18n.language && (
                      <>
                        <span className={ isDoctor ? classes.nonboldtext : classes.blacktext }>
                          {resultsArrFirstHalf[0]}
                        </span>
                        <span className={ isDoctor ? classes.styleforsmalltext : classes.blackboldtext }>
                          {resultsArrSecondHalf[0]}
                        </span>
                        <span className={ isDoctor ? classes.nonboldtext : classes.blacktext }>
                          {resultsArrSecondHalf[1]}
                        </span>
                      </>
                    ) }

                    {
                      !isDoctor && (
                        <>
                          <a
                            target='_blank'
                            href={
                              isKitPGX
                                ? `https://idnalaboratories.com${ extraFieldInAddress }/process/`
                                : `https://idnagenomics.com${ extraFieldInAddress }/product/${ productName }/#av-tab-section-1-5-link`
                            }
                            rel='noreferrer'
                          >
                            {t('')}
                          </a>
                        </>
                      )
                    }
                  </div>
                </div>
              </div>
            </Alert>
          </Grid>
        </form>
      </Container>
    </>
  );
};

SampleCollectionForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

SampleCollectionForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default SampleCollectionForm;
