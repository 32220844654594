import { FormControl, FormHelperText, MenuItem } from '@material-ui/core';
import Select from '@material-ui/core/Select';
import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import InputLabel from '@material-ui/core/InputLabel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useQuery } from 'react-query';
import useAPI from 'hooks/api-hooks';
import { useTranslation } from 'react-i18next';
import * as _ from 'lodash';

const CountySelectField = (
  {
    name,
    defaultValue,
    label,
    autoComplete,
    error,
    helperText,
    required,
    readOnly,
    loading,
    validate = null,
    disabled,
    setExternalValue = undefined,
    ...rest
  },
) => {
  const [ value, setValue ] = useState('');
  const { client } = useAPI();
  const { t, i18n } = useTranslation();

  const { data: counties, isLoading: countiesLoading } = useQuery(
    [ 'counties' ], () => {
      return client.get('counties');
    },
  );

  const sortedCounties = useMemo(() => {
    if (!counties) { return []; }
    return _.orderBy(counties, (a) => { return t(`common.counties.${ a.county_name }`); });
  }, [ counties, i18n.language ]);

  useEffect(() => {
    if ('' !== defaultValue && !defaultValue) {
      return;
    }
    setValue(defaultValue);

    if (setExternalValue) {
      setExternalValue(defaultValue);
    }
  }, [ defaultValue ]);

  return (
    <>
      <FormControl
        fullWidth
        error={ error }
        variant='outlined'
        disabled={ disabled }
        size='medium'
      >
        <InputLabel>{ `${ t(label) }${ required ? ' *' : '' }` }</InputLabel>
        <Select
          disabled={ !!readOnly || disabled }
          color='secondary'
          value={ value }
          label={ `${ t(label) }${ required ? ' *' : '' }` }
          onChange={ (e) => {
            if (error && null !== validate) {
              validate(e.target.value);
            }

            if (setExternalValue !== undefined) {
              setExternalValue(e.target.value);
            }

            return setValue(e.target.value);
          } }
          name={ name }
          autoComplete={ autoComplete }
          endAdornment={ (
            <>
              {(loading || countiesLoading)
                ? <CircularProgress color='inherit' size={ 20 } /> : null}
              {rest?.InputProps?.endAdornment ? rest.InputProps.endAdornment : null}
            </>
          ) }
          { ...rest }
        >
          {
            sortedCounties?.map((spec) => {
              // eslint-disable-next-line prefer-template
              const county = 'common.counties.' + spec.county_name;
              return (
                <MenuItem value={ spec.county_name } key={ spec.id }>
                  {t(county)}
                </MenuItem>
              );
            })
          }
        </Select>
      </FormControl>
      { error && (
        <FormControl>
          <FormHelperText margin='dense' error>
            { t(helperText) }
          </FormHelperText>
        </FormControl>
      ) }

    </>
  );
};

CountySelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

CountySelectField.defaultProps = {
  defaultValue: '',
  autoComplete: null,
  error: false,
  helperText: null,
  required: false,
  readOnly: false,
  loading: false,
};

export default CountySelectField;
