/* eslint-disable no-unused-vars */
import {
  FaUserFriends,
  FiHome,
} from 'react-icons/all';
import { useOptionSerial, useFetching } from 'NavigationProvider';
import NutritionPdfVersion from 'pages/Nutrition/NutritionPdfVersion';
import { useKits } from 'models/kits';
import React, { useMemo } from 'react';
import Nutrition from 'pages/Nutrition/Nutrition';
import UserNotificationsPage from 'pages/UserNotificationsPage';
import NCardiometabolicHealth from 'pages/Nutrition/NCardioMetabolicHealth';
import NCarboHydrates from 'pages/Nutrition/Profiles/NCarboHydrates';
import NProteins from 'pages/Nutrition/Profiles/NProteins';
import NFats from 'pages/Nutrition/Profiles/NFats';
import NSnacksBetweenMeals from 'pages/Nutrition/Profiles/NSnacksBetweenMeals';
import NSweetTaste from 'pages/Nutrition/Profiles/NSweetTaste';
import NTraceElements from 'pages/Vitamins/NTraceElements';
import NVitamins from 'pages/Vitamins/NVitamins';
import NDetoxificationCapacityAntioxidantNeeds from 'pages/Vitamins/NDetoxificationCapacityAntioxidantNeeds';
import NBiologicalClock from 'pages/Nutrition/Profiles/NBiologicalClock';
import NWeightManagement from 'pages/Nutrition/NWeightManagement';
import NSaturatedFat from 'pages/Nutrition/Profiles/NSaturatedFat';
import NSensitivityToSalt from 'pages/Nutrition/Profiles/NSensitivityToSalt';
import NSensitivityToCoffee from 'pages/Nutrition/Profiles/NSensitivityToCoffee';
import NSensitivityToAlcohol from 'pages/Nutrition/Profiles/NSensitivityToAlcohol';
import NSensitivityToGluten from 'pages/Nutrition/Profiles/NSensitivityToGluten';
import NSensitivityToLactose from 'pages/Nutrition/Profiles/NSensitivityToLactose';
import NFattyAcids from 'pages/Nutrition/Profiles/NFattyAcids';
import NTransFats from 'pages/Nutrition/Profiles/NTransFats';
import NFoodSensitivities from 'pages/Nutrition/NFoodSensitivities';
import ReferAFriendPage from 'pages/ReferAFriendPage';
import { Icon } from '@material-ui/core';
import iDNA3ToOne from 'pages/iDNA3ToOne/iDNA3ToOne';
import Results from 'pages/Results';
import MyDoctors from 'pages/customer-kits-overview/pages/MyDoctors';
import ThreeToOnePdfViewer from 'pages/iDNA3ToOne/ThreeToOnePdfViewer';
import InviteADoctor from 'pages/InviteADoctor';
import iDNACardioHealth from 'pages/iDNACardioHealth/iDNACardioHealth';
import CardioResults from 'pages/iDNACardioHealth/Common';
import ImportantInformationCardio from 'pages/iDNACardioHealth/ImportantInformationCardio';
import CardioResultsQuestionnaire from 'pages/iDNACardioHealth/CardioResultsQuestionnaire';
import KardiakhAneparkeia from 'pages/iDNACardioHealth/Results/KardiakhAneparkeia';
import EgkefalikoEpeisodeio from 'pages/iDNACardioHealth/Results/EgkefalikoEpeisodeio';
import Arrythmia from 'pages/iDNACardioHealth/Results/Arrythmia';
import CardioQuestionnaireAnswers from 'pages/iDNACardioHealth/CardioQuestionnaireAnswers';
import CardioPdfViewer from 'pages/iDNACardioHealth/CardioPdfViewer';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DiatatikiMyocardioPathia from 'pages/iDNACardioHealth/Results/DiatatikiMyocardioPathia';
import YpertrofikiMyocardioPathia from 'pages/iDNACardioHealth/Results/YpertrofikiMyocardioPathia';
import VitaminsPage from 'pages/Vitamins/VitaminsPage';
import WeightControl from 'pages/iDNA3ToOne/WeightControl';
import CardiometabolicHealth from 'pages/iDNA3ToOne/CardiometabolicHealth';
import SensitivityToLactose from 'pages/iDNA3ToOne/SensitivityProfiles/SensitivityToLactose';
import SensitivityToCoffee from 'pages/iDNA3ToOne/SensitivityProfiles/SensitivityToCoffee';
import Carbohydrates from 'pages/iDNA3ToOne/SensitivityProfiles/Carbohydrates';
import FattyAcids from 'pages/iDNA3ToOne/SensitivityProfiles/FattyAcids';
import TransFat from 'pages/iDNA3ToOne/SensitivityProfiles/TransFat';
import biologicalClock from 'pages/iDNA3ToOne/SensitivityProfiles/BiologicalClock';
import SensitivityToSalt from 'pages/iDNA3ToOne/SensitivityProfiles/SensitivityToSalt';
import SaturatedFat from 'pages/iDNA3ToOne/SensitivityProfiles/SaturatedFat';
import PreferenceForSweetTaste from 'pages/iDNA3ToOne/SensitivityProfiles/PreferenceForSweetTaste';
import Proteins from 'pages/iDNA3ToOne/SensitivityProfiles/Proteins';
import Fats from 'pages/iDNA3ToOne/SensitivityProfiles/Fats';
import Snacks from 'pages/iDNA3ToOne/SensitivityProfiles/Snacks';
import SensitivityToAlcohol from 'pages/iDNA3ToOne/SensitivityProfiles/SensitivityToAlcohol';
import SensitivityToGluten from 'pages/iDNA3ToOne/SensitivityProfiles/SensitivityToGluten';
import FoodSensitivities from 'pages/iDNA3ToOne/FoodSensitivities';
import Vitamins from 'pages/iDNA3ToOne/Vitamins';
import Magnesium from 'pages/iDNA3ToOne/SensitivityProfiles/Magnesium';
import HighIron from 'pages/iDNA3ToOne/SensitivityProfiles/HighIron';
import LowIron from 'pages/iDNA3ToOne/SensitivityProfiles/LowIron';
import HighCalcium from 'pages/iDNA3ToOne/SensitivityProfiles/HighCalcium';
import AntioxidantNeeds from 'pages/iDNA3ToOne/SensitivityProfiles/AntioxidantNeeds';
import LowCalcium from 'pages/iDNA3ToOne/SensitivityProfiles/LowCalcium';
import DetoxificationCapacity from 'pages/iDNA3ToOne/SensitivityProfiles/DetoxificationCapacity';
import VitaminD from 'pages/iDNA3ToOne/SensitivityProfiles/VitaminD';
import VitaminE from 'pages/iDNA3ToOne/SensitivityProfiles/VitaminE';
import VitaminC from 'pages/iDNA3ToOne/SensitivityProfiles/VitaminC';
import VitaminB12 from 'pages/iDNA3ToOne/SensitivityProfiles/VitaminB12';
import VitaminB9Acid from 'pages/iDNA3ToOne/SensitivityProfiles/VitaminB9Acid';
import VitaminA from 'pages/iDNA3ToOne/SensitivityProfiles/VitaminA';
import VitaminB6 from 'pages/iDNA3ToOne/SensitivityProfiles/VitaminB6';
import DetoxificationCapacityAntioxidantNeeds from 'pages/iDNA3ToOne/DetoxificationCapacityAntioxidantNeeds';
import TraceElements from 'pages/iDNA3ToOne/TraceElements';
import AthleticProfile from 'pages/iDNA3ToOne/AthleticProfile';
import RecoveryAfterExercise from 'pages/iDNA3ToOne/SensitivityProfiles/RecoveryAfterExercise';
import MusculoskeletalHealth from 'pages/iDNA3ToOne/SensitivityProfiles/MusculoskeletalHealth';
import AchillesTendonInjury from 'pages/iDNA3ToOne/SensitivityProfiles/AchillesTendonInjury';
import KneeElbowInjury from 'pages/iDNA3ToOne/SensitivityProfiles/KneeElbowInjury';
import PainTolerance from 'pages/iDNA3ToOne/SensitivityProfiles/PainTolerance';
import WantToExercise from 'pages/iDNA3ToOne/SensitivityProfiles/WantToExercise';
import IncreaseMuscle from 'pages/iDNA3ToOne/SensitivityProfiles/IncreaseMuscle';
import Aerobics from 'pages/iDNA3ToOne/SensitivityProfiles/Aerobics';
import Power from 'pages/iDNA3ToOne/SensitivityProfiles/Power';
import Stamina from 'pages/iDNA3ToOne/SensitivityProfiles/Stamina';
import InjuryPredisposition from 'pages/iDNA3ToOne/InjuryPredisposition';
import Strength from 'pages/iDNA3ToOne/SensitivityProfiles/Strength';
import NDetoxificationCapacity from 'pages/Vitamins/Profiles/NDetoxificationCapacity';
import NAntioxidantNeeds from 'pages/Vitamins/Profiles/NAntioxidantNeeds';
import NVitaminD from 'pages/Vitamins/Profiles/NVitaminD';
import NVitaminE from 'pages/Vitamins/Profiles/NVitaminE';
import NVitaminC from 'pages/Vitamins/Profiles/NVitaminC';
import NVitaminB9Acid from 'pages/Vitamins/Profiles/NVitaminB9Acid';
import NVitaminA from 'pages/Vitamins/Profiles/NVitaminA';
import NVitaminB6 from 'pages/Vitamins/Profiles/NVitaminB6';
import NVitaminB12 from 'pages/Vitamins/Profiles/NVitaminB12';
import NLowCalcium from 'pages/Vitamins/Profiles/NLowCalcium';
import NHighCalcium from 'pages/Vitamins/Profiles/NHighCalcium';
import NLowIron from 'pages/Vitamins/Profiles/NLowIron';
import NHighIron from 'pages/Vitamins/Profiles/NHighIron';
import NMagnesium from 'pages/Vitamins/Profiles/NMagnesium';
import Sports from 'pages/Sports/Sports';
import SportsPdfVersion from 'pages/Sports/SportsPdfVersion';
import VitaminsPdfVersion from 'pages/Vitamins/VitaminsPdfVersion';
import NAthleticProfile from 'pages/Sports/NAthleticProfile';
import NInjuryPredisposition from 'pages/Sports/NInjuryPredisposition';
import NKneeElbowInjury from 'pages/Sports/Profiles/NKneeElbowInjury';
import NAchillesTendonInjury from 'pages/Sports/Profiles/NAchillesTendonInjury';
import NMusculoskeletalHealth from 'pages/Sports/Profiles/NMusculoskeletalHealth';
import NRecoveryAfterExercise from 'pages/Sports/Profiles/NRecoveryAfterExercise';
import NStrength from 'pages/Sports/Profiles/NStrength';
import NPower from 'pages/Sports/Profiles/NPower';
import NStamina from 'pages/Sports/Profiles/NStamina';
import NPainTolerance from 'pages/Sports/Profiles/NPainTolerance';
import NAerobics from 'pages/Sports/Profiles/NAerobics';
import NIncreaseMuscle from 'pages/Sports/Profiles/NIncreaseMuscle';
import NWantToExercise from 'pages/Sports/Profiles/NWantToExercise';
import BiologicalAge from 'pages/BiologicalAge/BiologicalAge';
import BioAgePdfVersion from 'pages/BiologicalAge/BioAgePdfVersion';
import BioAgeResults from 'pages/BiologicalAge/BioAgeResults';
import PGx from 'pages/PGx/PGx';
import PGxPdfVersion from 'pages/PGx/PGxPdfVersion';
import NutriGenetix from 'pages/NutriGenetix/NutriGenetix';
import NutriGenetixPdfVersion from 'pages/NutriGenetix/NutriGenetixPdfVersion';
import NutriGenetixQuestionnaireAnswers from 'pages/NutriGenetix/NutriGenetixQuestionnaireAnswers';
import BioAgeQuestionnaireAnswers from 'pages/BiologicalAge/BioAgeQuestionnaireAnswers';
import NutriGenetixProfile from 'pages/NutriGenetix/NutriGenetixProfile';
import NutriGenetixDND from 'pages/NutriGenetix/NutriGenetixDND';
import NutriGenetixPlan from 'pages/NutriGenetix/NutriGenetixPlan';
import NutritionExplanation from 'pages/Nutrition/NutritionExplanation';
import VitaminsExplanation from 'pages/Vitamins/VitaminsExplanation';
import ThreeToOneExplanation from 'pages/iDNA3ToOne/ThreeToOneExplanation';
import BioAgeHistory from 'pages/BiologicalAge/BioAgeHistory';
import BioAgeAnalysis from 'pages/BiologicalAge/BioAgeAnalysis';
import NutrigenetixExplanation from 'pages/NutriGenetix/NutrigenetixExplanation';
import WelcomePage from '../../../pages/WelcomePage';
import NoDataPage from '../../../pages/NoDataPage';
import ProfilePage from '../../../pages/ProfilePage';
import CardBase from '../../app/CardBase';
import KitRegistrationDashboard from '../KitRegistrationDashboard';
import Invitations from '../../../pages/customer-kits-overview/pages/Invitations';
import StefaniaNosos from '../../../pages/iDNACardioHealth/Results/StefaniaNosos';

const CardBaseWrapper = ({ children }) => {
  return (
    <CardBase withShadow align='left' style={ { background: '#F1F3F3' } }>
      {children}
    </CardBase>
  );
};

export const boxAroundIcon = {
  // height: '10vh',
  // width: '10vh',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#EDFBD8',
  border: '0.5px solid #8FC440',
  boxShadow: '0px 0px 9px rgba(143, 196, 64, 0.46)',
  borderRadius: '10px',
};

export const boxAroundIconDoctor = {
  // height: '10vh',
  // width: '10vh',
  padding: '20px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#b8cee0',
  border: '0.5px solid #b8cee0',
  boxShadow: '0px 0px 9px rgba(63, 78, 89, 0.46)',
  borderRadius: '10px',
};

const bigIcon = {
  height: '50px',
  width: '50px',
};

const profileIcon = ({ isActive }) => {
  return (
    <AccountCircleIcon
      style={ { color: '#666666',
        filter: isActive ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : 'none' } }
    />
  );
};

export const profileIconBig = () => {
  return (
    <div style={ boxAroundIcon }>
      <AccountCircleIcon style={ { height: '50px', width: '50px' } } />
    </div>
  );
};

export const registeredKitsIconBig = () => {
  return (
    <div style={ boxAroundIcon }>
      <img src='/checked-50x50px.svg' alt='' style={ bigIcon } />
    </div>
  );
};

export const newKitIconBig = () => {
  return (
    <div style={ boxAroundIcon }>
      <img src='/newKit-50x50px.svg' alt='' />
    </div>
  );
};

export const resultsIconBig = () => {
  return (
    <div style={ boxAroundIcon }>
      <img src='/results-50x50px.svg' alt='' />
    </div>
  );
};

const folderIconBig = ({ isDisabled }) => {
  return (
    <div style={ { ...boxAroundIcon, background: true === isDisabled ? '#a8a8a8' : '#EDFBD8' } }>
      <img src='/folder-50x50px.svg' alt='' />
    </div>
  );
};

const doctorsIconBig = () => {
  return (
    <div style={ boxAroundIcon }>
      <img src='/doctors-50x50px.svg' alt='' />
    </div>
  );
};

const notificationsIcon = ({ isActive }) => {
  return (
    <Icon>
      <img
        style={ { width: '23px',
          height: '23px',
          filter: isActive ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : 'none' } }
        src='/no_notifications.svg'
        alt=''
      />
    </Icon>
  );
};

const mobileIconBig = ({ isDisabled }) => {
  return (
    <div style={ { ...boxAroundIcon, background: true === isDisabled ? '#a8a8a8' : '#EDFBD8' } }>
      <img src='/mobile-50x50px.svg' alt='' />
    </div>
  );
};

const registeredKitsIcon = ({ isActive }) => {
  return (
    <Icon>
      <img
        src='/checked.svg'
        alt=''
        style={ { filter: isActive ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : 'none' } }
      />
    </Icon>
  );
};

export const newKitIcon = () => {
  return (
    <Icon>
      <img src='/newKit.svg' alt='' />
    </Icon>
  );
};

export const resultsIcon = ({ isActive }) => {
  return (
    <Icon color='secondary'>
      <img
        src='/results.svg'
        alt=''
        style={ { filter: isActive ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : 'none' } }
      />
    </Icon>
  );
};

const folderIcon = ({ isActive }) => {
  return (
    <Icon>
      <img
        src='/folder.svg'
        alt=''
        style={ { filter: isActive ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : 'none' } }
      />
    </Icon>
  );
};

const doctorsIcon = ({ isActive }) => {
  return (
    <Icon>
      <img
        src='/doctors.svg'
        alt=''
        style={ { filter: isActive ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : 'none' } }
      />
    </Icon>
  );
};

const mobileIcon = ({ isActive, isDisabled }) => {
  return (
    <Icon>
      <img
        src='/mobile.svg'
        alt=''
        style={ { filter: isActive ? 'brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%) contrast(103%)' : 'none',
          background: true === isDisabled ? '#a8a8a8' : '' } }
      />
    </Icon>
  );
};

const referIcon = ({ isActive: _isActive }) => {
  return (
    <FaUserFriends />
  );
};

const homeIcon = ({ isActive }) => {
  return (
    <FiHome color={ isActive ? 'white' : '#666666' } />
  );
};

const usePatientNavigationPages = () => {
  const { serial: nutritionalSerial } = useOptionSerial('nutrition');
  const { serial: threeInOneSerial } = useOptionSerial('3-in-1');
  const { serial: vitaminsSerial } = useOptionSerial('vitamins');
  const { serial: nutrigenetixSerial } = useOptionSerial('nutriGenetix');

  const { fetchKits } = useFetching();
  const { data: kits } = useKits(fetchKits);

  const showAppointment = (serialNumber) => {
    if (null == kits) {
      return false;
    }

    const foundKit = kits?.find((kit) => {
      return kit.serial_number_id.serial === serialNumber;
    });

    return true === foundKit?.appointment_availability
    || 'paid' === foundKit?.serial_number_id?.report_payment_status;
  };

  const doesExist = (name) => {
    if (null == kits) {
      return false;
    }

    return null != kits.find((kit) => {
      if (null != kit.thematic_package && kit.thematic_package.thematic_category_id.name === name
        && 'done' === kit.registrationStatus
      ) {
        return true;
      }
      return false;
    });
  };

  const navigationPages = useMemo(() => {
    return [
      {
        id: 'general',
        title: 'home',
        Component: WelcomePage,
        wrapperComponent: null,
        Icon: homeIcon,
        BigIcon: registeredKitsIconBig,
        type: 'item',
      },
      {
        id: 'newKitRegister',
        title: 'newKitRegister',
        Component: KitRegistrationDashboard,
        wrapperComponent: null,
        Icon: newKitIcon,
        BigIcon: newKitIconBig,
        type: 'item',
      },
      {
        id: 'registeredKits',
        title: 'registeredKits',
        Component: Invitations,
        wrapperComponent: CardBaseWrapper,
        Icon: registeredKitsIcon,
        BigIcon: registeredKitsIconBig,
        type: 'item',
      },
      {
        id: 'results',
        title: 'results',
        Component: Results,
        wrapperComponent: null,
        Icon: resultsIcon,
        BigIcon: resultsIconBig,
        type: 'folder',
        items:
        [
          {
            id: 'iDNAPGxCNS',
            color: '#526A9D',
            title: 'iDNA PGx CNS Report',
            Component: PGx,
            wrapperComponent: null,
            type: 'folder',
            enabled: doesExist('PGx'),
            items: [
              {
                id: 'pgxPdfVersion',
                title: 'PDF version',
                Component: PGxPdfVersion,
                wrapperComponent: CardBaseWrapper,
                color: '#526A9D',
                type: 'item',
              },
            ],
          },
          {
            id: 'iDNACardioHealth',
            title: 'iDNA Cardio Health',
            Component: iDNACardioHealth,
            wrapperComponent: null,
            type: 'folder',
            enabled: doesExist('IDNA Cardio Health') || doesExist('Cardio Health'),
            items: [
              {
                id: 'stefaniaNososResults',
                title: 'Stefania Nosos',
                Component: StefaniaNosos,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'diatatikiMyocardioPathiaResults',
                title: 'Diatatiki MyocardioPathia',
                Component: DiatatikiMyocardioPathia,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'ypertrofikiMyocardioPathiaResults',
                title: 'Ypertrofiki MyocardioPathia',
                Component: YpertrofikiMyocardioPathia,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'kardiakhAneparkeiaResults',
                title: 'Kardiakh Aneparkeia',
                Component: KardiakhAneparkeia,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'ArrythmiaResults',
                title: 'Arrythmia',
                Component: Arrythmia,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'egkefalikoResults',
                title: 'egkefalikoEpeisodeio',
                Component: EgkefalikoEpeisodeio,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'pdfVersionCardio',
                title: 'PDF version',
                Component: CardioPdfViewer,
                wrapperComponent: CardBaseWrapper,
                type: 'item',
              },
              {
                id: 'cardioQuestionnaireAnswers',
                title: 'questionnaireAnswers',
                Component: CardioQuestionnaireAnswers,
                type: 'item',
              },
              {
                id: 'Αποτελεσματα',
                title: 'Αποτελεσματα',
                Component: CardioResults,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'import-info-cardio',
                title: 'import-info-cardio',
                Component: ImportantInformationCardio,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'questionnaire-cardio-health',
                title: 'questionnaire-cardio-health',
                Component: CardioResultsQuestionnaire,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
            ],
          },

          {
            id: 'nutriGenetix',
            title: 'iDNA NutriGenetix',
            Component: NutriGenetix,
            wrapperComponent: null,
            type: 'folder',
            color: '#7C81C0',
            enabled: doesExist('NutriGenetix') || doesExist('NutriGenetix-Laboratories'),
            items: [
              {
                id: 'nutriGenetixAnalysisPdfVersion',
                title: 'Διατροφογενετική Aνάλυση PDF Version',
                Component: NutriGenetixPdfVersion,
                wrapperComponent: CardBaseWrapper,
                color: '#7C81C0',
                type: 'item',
              },
              {
                id: 'nutriGenetixNutritionPlanPdfVersion',
                title: 'Πλάνο Διατροφής PDF Version',
                Component: NutriGenetixPdfVersion,
                wrapperComponent: CardBaseWrapper,
                color: '#7C81C0',
                type: 'item',
              },
              {
                id: 'nutrigenetixQuestionnaireAnswers',
                title: 'questionnaireAnswers',
                color: '#7C81C0',
                Component: NutriGenetixQuestionnaireAnswers,
                type: 'item',
              },
              {
                id: 'nutriGenetixProfile',
                title: 'questionnaireAnswers',
                color: '#7C81C0',
                Component: NutriGenetixProfile,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutriGenetixDVD',
                title: 'questionnaireAnswers',
                color: '#7C81C0',
                Component: NutriGenetixDND,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutriGenetixPlan',
                title: 'questionnaireAnswers',
                color: '#7C81C0',
                Component: NutriGenetixPlan,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutrigenetixExplanationOfResults',
                title: 'explanationOfResults',
                Component: NutrigenetixExplanation,
                wrapperComponent: null,
                color: '#7C81C0',
                type: 'item',
                enabled: true,
              },
            ],
          },

          {
            id: 'biologicalAge',
            title: 'iDNA Βιολογική Ηλικία',
            Component: BiologicalAge,
            wrapperComponent: null,
            type: 'folder',
            enabled: doesExist('Biological Age'),
            color: '#FF7B00',
            items: [
              {
                id: 'bioAgeResults',
                title: 'Biological Age Results',
                Component: BioAgeResults,
                color: '#FF7B00',
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'bioAgeAnalysis',
                title: 'Biological Age Analysis',
                Component: BioAgeAnalysis,
                color: '#FF7B00',
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'bioAgeHistory',
                title: 'Biological Age History',
                Component: BioAgeHistory,
                color: '#FF7B00',
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'bioAgePdfVersion',
                title: 'PDF version',
                Component: BioAgePdfVersion,
                color: '#FF7B00',
                wrapperComponent: CardBaseWrapper,
                type: 'item',
              },
              {
                id: 'bioAgeQuestionnaireAnswers',
                title: 'questionnaireAnswers',
                Component: BioAgeQuestionnaireAnswers,
                color: '#FF7B00',
                type: 'item',
              },
            ],
          },

          {
            id: 'vitaminsPage',
            title: 'vitamins',
            Component: VitaminsPage,
            wrapperComponent: null,
            color: '#93C221',
            type: 'folder',
            enabled: doesExist('Vitamins'),
            items: [
              {
                id: 'vitaminsDetoxificationCapacityAntioxidantNeeds',
                title: 'detoxificationCapacityAntioxidantNeeds',
                Component: NDetoxificationCapacityAntioxidantNeeds,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsAntioxidantNeeds',
                title: 'antioxidantNeeds',
                Component: NAntioxidantNeeds,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsVitamins',
                title: 'vitamins',
                Component: NVitamins,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsTraceElements',
                title: 'traceElements',
                Component: NTraceElements,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsDetoxificationCapacity',
                title: 'traceElements',
                Component: NDetoxificationCapacity,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsPdfVersion',
                title: 'PDF version',
                Component: VitaminsPdfVersion,
                wrapperComponent: CardBaseWrapper,
                type: 'item',
                color: '#93C221',
              },
              {
                id: 'vitaminsExplanationOfResults',
                title: 'explanationOfResults',
                Component: VitaminsExplanation,
                wrapperComponent: null,
                type: 'item',
                color: '#93C221',
                enabled: showAppointment(vitaminsSerial),
              },
              {
                id: 'vitaminsVitaminD',
                title: 'vitaminD',
                Component: NVitaminD,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsVitaminE',
                title: 'vitaminE',
                Component: NVitaminE,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsVitaminC',
                title: 'vitaminC',
                Component: NVitaminC,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsVitaminB12',
                title: 'vitaminB12',
                Component: NVitaminB12,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsVitaminB9Acid',
                title: 'vitaminB9Acid',
                Component: NVitaminB9Acid,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsVitaminA',
                title: 'vitaminA',
                Component: NVitaminA,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsVitaminB6',
                title: 'vitaminB6',
                Component: NVitaminB6,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },

              {
                id: 'vitaminsLowCalcium',
                title: 'lowCalcium',
                Component: NLowCalcium,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsHighCalcium',
                title: 'highCalcium',
                Component: NHighCalcium,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsLowIron',
                title: 'lowIron',
                Component: NLowIron,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsHighIron',
                title: 'highIron',
                Component: NHighIron,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsMagnesium',
                title: 'magnesium',
                Component: NMagnesium,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
            ],
          },
          {
            id: 'sportsPage',
            title: 'Άθληση',
            Component: Sports,
            wrapperComponent: null,
            color: '#31B5E1',
            type: 'folder',
            enabled: doesExist('Sports'),
            items: [
              {
                id: 'sportsPdfVersion',
                title: 'PDF version',
                Component: SportsPdfVersion,
                wrapperComponent: CardBaseWrapper,
                type: 'item',
                color: '#31B5E1',
              },
              {
                id: 'sportsAthleticProfile',
                title: 'athleticProfile',
                Component: NAthleticProfile,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsInjuryPredisposition',
                title: 'injuryPredisposition',
                Component: NInjuryPredisposition,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },

              {
                id: 'sportsKneeElbowInjury',
                title: 'kneeElbowInjury',
                Component: NKneeElbowInjury,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsAchillesTendonInjury',
                title: 'achillesTendonInjury',
                Component: NAchillesTendonInjury,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsMusculoskeletalHealth',
                title: 'musculoskeletalHealth',
                Component: NMusculoskeletalHealth,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsRecoveryAfterExercise',
                title: 'recoveryAfterExercise',
                Component: NRecoveryAfterExercise,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsStrength',
                title: 'strength',
                Component: NStrength,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsPower',
                title: 'power',
                Component: NPower,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsStamina',
                title: 'stamina',
                Component: NStamina,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsPainTolerance',
                title: 'painTolerance',
                Component: NPainTolerance,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsAerobics',
                title: 'aerobics',
                Component: NAerobics,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsIncreaseMuscle',
                title: 'increaseMuscle',
                Component: NIncreaseMuscle,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sportsWantToExercise',
                title: 'wantToExercise',
                Component: NWantToExercise,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
            ],
          },

          {
            id: 'iDNATest3to1',
            title: 'iDNATest3to1',
            Component: iDNA3ToOne,
            wrapperComponent: null,
            type: 'folder',
            enabled: doesExist('3-in-1'),
            items: [
              {
                id: 'diet',
                alternativeIds: [
                  'weightManagement', 'foodSensitivities', 'cardiometabolicHealth',
                  'carbohydrates', 'proteins', 'snacksBetweenMeals', 'fats',
                  'sweetTaste', 'biologicalClock', 'sensitivityToSalt',
                  'saturatedFat', 'fattyAcids', 'transFats', 'sensitivityToCoffee',
                  'sensitivityToAlcohol', 'sensitivityToLactose', 'sensitivityToGluten' ],
                title: 'diet',
                Component: WeightControl,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
                color: '#F5C91E',
              },
              {
                id: 'weightManagement',
                title: 'weightManagement',
                Component: WeightControl,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminsHome',
                title: 'vitamins',
                alternativeIds: [ 'vitamins', 'detoxificationCapacityAntioxidantNeeds', 'traceElements',
                  'vitaminA', 'vitaminB6', 'vitaminB9Acid', 'vitaminB12',
                  'vitaminC', 'vitaminD', 'vitaminE', 'detoxificationCapacity',
                  'antioxidantNeeds', 'lowCalcium', 'highCalcium', 'lowIron', 'highIron',
                  'magnesium' ],
                Component: Vitamins,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'cardiometabolicHealth',
                title: 'cardiometabolicHealth',
                Component: CardiometabolicHealth,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sensitivityToLactose',
                title: 'sensitivityToLactose',
                Component: SensitivityToLactose,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sensitivityToCoffee',
                title: 'sensitivityToCoffee',
                Component: SensitivityToCoffee,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'carbohydrates',
                title: 'carbohydrates',
                Component: Carbohydrates,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'fattyAcids',
                title: 'fattyAcids',
                Component: FattyAcids,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'transFats',
                title: 'transFats',
                Component: TransFat,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'biologicalClock',
                title: 'biologicalClock',
                Component: biologicalClock,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sensitivityToSalt',
                title: 'sensitivityToSalt',
                Component: SensitivityToSalt,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'saturatedFat',
                title: 'saturatedFat',
                Component: SaturatedFat,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sweetTaste',
                title: 'sweetTaste',
                Component: PreferenceForSweetTaste,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'proteins',
                title: 'proteins',
                Component: Proteins,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'fats',
                title: 'fats',
                Component: Fats,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'snacksBetweenMeals',
                title: 'snacksBetweenMeals',
                Component: Snacks,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sensitivityToAlcohol',
                title: 'sensitivityToAlcohol',
                Component: SensitivityToAlcohol,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sensitivityToGluten',
                title: 'sensitivityToGluten',
                Component: SensitivityToGluten,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'foodSensitivities',
                title: 'foodSensitivities',
                Component: FoodSensitivities,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'magnesium',
                title: 'magnesium',
                Component: Magnesium,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'highIron',
                title: 'highIron',
                Component: HighIron,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'lowIron',
                title: 'lowIron',
                Component: LowIron,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'highCalcium',
                title: 'highCalcium',
                Component: HighCalcium,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'antioxidantNeeds',
                title: 'antioxidantNeeds',
                Component: AntioxidantNeeds,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'lowCalcium',
                title: 'lowCalcium',
                Component: LowCalcium,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'detoxificationCapacity',
                title: 'detoxificationCapacity',
                Component: DetoxificationCapacity,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminD',
                title: 'vitaminD',
                Component: VitaminD,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminE',
                title: 'vitaminE',
                Component: VitaminE,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminC',
                title: 'vitaminC',
                Component: VitaminC,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminB12',
                title: 'vitaminB12',
                Component: VitaminB12,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminB9Acid',
                title: 'vitaminB9Acid',
                Component: VitaminB9Acid,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminA',
                title: 'vitaminA',
                Component: VitaminA,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitaminB6',
                title: 'vitaminB6',
                Component: VitaminB6,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'vitamins',
                title: 'vitamins',
                Component: Vitamins,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'detoxificationCapacityAntioxidantNeeds',
                title: 'detoxificationCapacityAntioxidantNeeds',
                Component: DetoxificationCapacityAntioxidantNeeds,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'traceElements',
                title: 'traceElements',
                Component: TraceElements,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'sports',
                title: 'sports',
                alternativeIds: [ 'injuryPredisposition', 'athleticProfile', 'stamina',
                  'strength', 'power', 'aerobics', 'increaseMuscle', 'wantToExercise', 'painTolerance',
                  'kneeElbowInjury', 'achillesTendonInjury', 'musculoskeletalHealth',
                  'recoveryAfterExercise' ],
                Component: AthleticProfile,
                wrapperComponent: null,
                isMenuHidden: true,
                type: 'item',
                color: '#31B5E1',
              },
              {
                id: 'recoveryAfterExercise',
                title: 'recoveryAfterExercise',
                Component: RecoveryAfterExercise,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'musculoskeletalHealth',
                title: 'musculoskeletalHealth',
                Component: MusculoskeletalHealth,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'achillesTendonInjury',
                title: 'achillesTendonInjury',
                Component: AchillesTendonInjury,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'kneeElbowInjury',
                title: 'kneeElbowInjury',
                Component: KneeElbowInjury,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'painTolerance',
                title: 'painTolerance',
                Component: PainTolerance,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'wantToExercise',
                title: 'wantToExercise',
                Component: WantToExercise,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'increaseMuscle',
                title: 'increaseMuscle',
                Component: IncreaseMuscle,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'aerobics',
                title: 'aerobics',
                Component: Aerobics,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'power',
                title: 'power',
                Component: Power,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'strength',
                title: 'strength',
                Component: Strength,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'stamina',
                title: 'stamina',
                Component: Stamina,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'injuryPredisposition',
                title: NInjuryPredisposition,
                Component: InjuryPredisposition,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'athleticProfile',
                title: 'athleticProfile',
                Component: AthleticProfile,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'pdfVersion',
                title: 'PDF version',
                Component: ThreeToOnePdfViewer,
                wrapperComponent: CardBaseWrapper,
                type: 'item',
              },
              {
                id: 'explanationOfResults',
                title: 'explanationOfResults',
                Component: ThreeToOneExplanation,
                wrapperComponent: null,
                type: 'item',
                enabled: showAppointment(threeInOneSerial),
              },
            ],
          },

          {
            id: 'nutritionalGuide',
            title: 'Διατροφή',
            Component: Nutrition,
            wrapperComponent: null,
            color: '#F5C91E',
            type: 'folder',
            enabled: doesExist('Nutrition'),
            items: [
              {
                id: 'nutritionWeightManagement',
                title: 'weightManagement',
                Component: NWeightManagement,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionFoodSensitivities',
                title: 'foodSensitivities',
                Component: NFoodSensitivities,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionCardiometabolicHealth',
                title: 'cardiometabolicHealth',
                Component: NCardiometabolicHealth,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionPdfVersion',
                title: 'PDF version',
                Component: NutritionPdfVersion,
                wrapperComponent: CardBaseWrapper,
                color: '#F5C91E',
                type: 'item',
              },
              {
                id: 'nutritionExplanationOfResults',
                title: 'explanationOfResults',
                Component: NutritionExplanation,
                wrapperComponent: null,
                color: '#F5C91E',
                type: 'item',
                enabled: showAppointment(nutritionalSerial),
              },
              {
                id: 'nutritionSensitivityToSalt',
                title: 'sensitivityToSalt',
                Component: NSensitivityToSalt,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionSaturatedFat',
                title: 'saturatedFat',
                Component: NSaturatedFat,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionFattyAcids',
                title: 'fattyAcids',
                Component: NFattyAcids,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionTransFats',
                title: 'transFats',
                Component: NTransFats,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionSensitivityToLactose',
                title: 'sensitivityToLactose',
                Component: NSensitivityToLactose,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionSensitivityToCoffee',
                title: 'sensitivityToCoffee',
                Component: NSensitivityToCoffee,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionSensitivityToAlcohol',
                title: 'sensitivityToAlcohol',
                Component: NSensitivityToAlcohol,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionSensitivityToGluten',
                title: 'sensitivityToGluten',
                Component: NSensitivityToGluten,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },

              {
                id: 'nutritionProteins',
                title: 'proteins',
                Component: NProteins,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionSnacksBetweenMeals',
                title: 'snacksBetweenMeals',
                Component: NSnacksBetweenMeals,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionCarbohydrates',
                title: 'carbohydrates',
                Component: NCarboHydrates,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionFats',
                title: 'fats',
                Component: NFats,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionSweetTaste',
                title: 'sweetTaste',
                Component: NSweetTaste,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
              {
                id: 'nutritionBiologicalClock',
                title: 'biologicalClock',
                Component: NBiologicalClock,
                wrapperComponent: null,
                type: 'item',
                isMenuHidden: true,
              },
            ],
          },
        ],
      },
      {
        id: 'personalFolder',
        title: 'personalFolder',
        Component: NoDataPage,
        wrapperComponent: CardBaseWrapper,
        Icon: folderIcon,
        BigIcon: folderIconBig,
        type: 'item',
        isDisabled: true,
        isMenuHidden: true,
      },
      {
        id: 'doctorsHealthProfessionals',
        title: 'doctorsHealthProfessionals',
        Component: MyDoctors,
        wrapperComponent: CardBaseWrapper,
        Icon: doctorsIcon,
        BigIcon: doctorsIconBig,
        alternativeIds: [ 'invite-a-doctor' ],
        type: 'item',
      },
      {
        id: 'mobile-data',
        title: 'data-from-mobile-app',
        Component: NoDataPage,
        wrapperComponent: CardBaseWrapper,
        Icon: mobileIcon,
        BigIcon: mobileIconBig,
        type: 'item',
        isDisabled: true,
        isMenuHidden: true,
      },
      {
        id: 'refer-a-friend',
        title: 'refer-a-friend',
        Component: ReferAFriendPage,
        wrapperComponent: CardBaseWrapper,
        Icon: referIcon,
        BigIcon: newKitIconBig,
        type: 'item',
        isMenuHidden: true,
      },
      {
        id: 'invite-a-doctor',
        title: 'inviteADoctor',
        Component: InviteADoctor,
        wrapperComponent: CardBaseWrapper,
        type: 'item',
        isMenuHidden: true,
      },
      {
        id: 'notifications',
        title: 'notifications',
        Component: UserNotificationsPage,
        Icon: notificationsIcon,
        wrapperComponent: CardBaseWrapper,
        isMenuHidden: true,
        isOnlyMobile: true,
      },
      {
        id: 'profile',
        title: 'profile',
        Component: ProfilePage,
        wrapperComponent: CardBaseWrapper,
        Icon: profileIcon,
        BigIcon: profileIconBig,
        type: 'item',
      },
    ];
  }, [ kits, nutritionalSerial, threeInOneSerial, vitaminsSerial, nutrigenetixSerial ]);

  return navigationPages;
};

export default usePatientNavigationPages;
