import React, { useState, useMemo, memo, useEffect, useRef, useLayoutEffect } from 'react';
import useAPI from 'hooks/api-hooks';
import { useQuery } from 'react-query';
import { Page } from 'react-pdf';
import { pdfjs, Document } from 'react-pdf/dist/esm/entry.webpack';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import useResizeObserver from '@react-hook/resize-observer';
import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'AuthCtx';

const useWidth = (target) => {
  const [ width, setWidth ] = useState(null);

  useLayoutEffect(() => {
    setWidth(target.current.getBoundingClientRect().width);
  }, [ target ]);

  useResizeObserver(target, (entry) => { return setWidth(entry.contentRect.width); });
  return width;
};

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${ pdfjs.version }/pdf.worker.min.js`;

const PDFViewerHelper = memo(({ resp, numPages, setNumPages }) => {
  const onDocumentLoadSuccess = ({ numPages: newPages }) => {
    setNumPages(newPages);
  };

  const wrapperDiv = useRef(null);
  const width = useWidth(wrapperDiv);

  return (
    <div className='wrapper' ref={ wrapperDiv }>
      <Document
        file={ { data: resp } }
        options={ { workerSrc: '/pdf.worker.js' } }
        onLoadSuccess={ onDocumentLoadSuccess }
        onLoadError={ console.error }
      >
        {
          Array.from(new Array(numPages), (el, index) => {
            return (
              <Page
                pageNumber={ index + 1 }
                width={ width }
                key={ `page_${ index + 1 }` }
              />
            );
          })
        }

      </Document>
    </div>
  );
});

const PDFViewerMiddle = ({ serial, hasPDF, isDoctor: doctorReport, nameSuffix }) => {
  const { client } = useAPI();

  const [ numPages, setNumPages ] = useState(null);
  const { t } = useTranslation();

  const { isDoctor } = useAuth();

  const {
    data,
    isLoading,
  } = useQuery(
    [ 'pdf-viewer', serial, hasPDF, doctorReport, nameSuffix ], () => {
      if (!hasPDF) {
        return null;
      }

      let name = serial;
      if (doctorReport) {
        name += '_doctor';
      } else if ('' !== nameSuffix) {
        name += nameSuffix;
      }

      return client.get(`/readPDFFile?fileName=${ name }.pdf`);
    },
  );

  if (!hasPDF) {
    return (
      <h1>Μη διαθέσιμο pdf</h1>
    );
  }

  if (isLoading) {
    return <LoaderSkeleton />;
  }

  const saveByteArray = () => {
    const u8 = new Uint8Array(data.Body.data);
    const blob = new Blob([ u8 ], { type: 'application/pdf' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    const fileName = `${ serial }.pdf`;
    link.download = fileName;
    link.click();
  };

  return (
    <div style={ { width: '100%' } }>
      <div style={ { display: 'flex', width: '100%', justifyContent: 'space-between', marginBottom: '1rem' } }>
        <h1>
          PDF
        </h1>
        <Button
          onClick={ saveByteArray }
          variant='contained'
          color='secondary'
          style={ { textTransform: 'none', color: isDoctor ? 'white' : '' } }
        >
          {t('download1')}
          {' '}
          PDF
        </Button>
      </div>

      <PDFViewerHelper
        resp={ data.Body.data }
        numPages={ numPages }
        setNumPages={ setNumPages }
      />
    </div>
  );
};

const PDFViewer = ({ serial, isDoctor = false, nameSuffix = '', fromInvitation = false }) => {
  const { client } = useAPI();
  const [ hasPDF, setHasPDF ] = useState(false);

  const {
    data: kit,
    isLoading,
  } = useQuery(
    [ 'registration-kit-one', serial, fromInvitation ],
    () => {
      if (fromInvitation) {
        return client.get(`customer-doctor-kits?registration_kit.serial_number_id.serial=${ serial }`).then((s) => {
          return [ s?.[0]?.registration_kit ];
        });
      }

      return client.get(`registration-kits?serial_number_id.serial=${ serial }`);
    },
  );

  useEffect(() => {
    if (!isLoading) {
      setHasPDF(kit[0].pdf_available);
    }
  }, [ kit ]);

  if (isLoading || serial === undefined) {
    return <LoaderSkeleton />;
  }

  return (
    <PDFViewerMiddle
      serial={ serial }
      hasPDF={ hasPDF }
      isDoctor={ isDoctor }
      nameSuffix={ nameSuffix }
    />
  );
};

export const FilePDFViewer = ({ path, showDownload = false }) => {
  const [ numPages, setNumPages ] = useState(null);

  const onDocumentLoadSuccess = ({ numPages: newPages }) => {
    setNumPages(newPages);
  };

  const { t } = useTranslation();
  const wrapperDiv = useRef(null);
  const width = useWidth(wrapperDiv);

  const PDF = useMemo(() => {
    return (
      <div className='wrapper' ref={ wrapperDiv }>
        <Document
          file={ path }
          options={ { workerSrc: '/pdf.worker.js' } }
          onLoadSuccess={ onDocumentLoadSuccess }
          onLoadError={ console.error }
        >
          {
            Array.from(new Array(numPages), (el, index) => {
              return (
                <Page
                  pageNumber={ index + 1 }
                  width={ width }
                  key={ `page_${ index + 1 }` }
                />
              );
            })
          }

        </Document>
      </div>
    );
  }, [ path, onDocumentLoadSuccess, numPages, width ]);

  return (
    <div style={ { height: '77vh', overflow: 'auto', width: '100%', margin: 0 } }>
      {
        showDownload && (
          <Button
            variant='contained'
            color='secondary'
          >
            <a
              href={ path }
              download
              target='_blank'
              rel='noreferrer'
              style={ { textDecoration: 'none', color: 'white' } }
            >
              {t('downloadFormal')}
            </a>
          </Button>
        )
      }
      <div>
        {PDF}
      </div>
    </div>
  );
};

PDFViewerHelper.displayName = 'PDFViewerHelper';
export default PDFViewer;
