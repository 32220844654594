import React from 'react';
import { Pdf } from 'pages/iDNACardioHealth/iDNACardioHealth';
import { useKits } from 'models/kits';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { BottomPart, iDNA3ToOneCards } from 'pages/iDNA3ToOne/iDNA3ToOne';
import { makeStyles, Icon } from '@material-ui/core';
import { useAuth } from 'AuthCtx';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/media-query-hooks';

export const greenColor = '#93C221';

const useStyles = makeStyles(() => {
  return {
    learnMoreArrow: {
      marginTop: '3px',
      transform: 'rotate(-45deg)',
    },
    cardIcon: {
      height: '71px',
      width: '71px',
      display: 'flex',
      padding: '15px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
    },
    page: {
      display: 'flex',
      padding: '30px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      minHeight: '250px',
      borderRadius: '16px',
      flexWrap: 'nowrap',
      gap: '0',
      '@media (max-width:600px)': {
        flexWrap: 'wrap',
        gap: '8px',
      },
    },
    smallText: {
      fontWeight: '400',
      fontSize: '16px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
    },
    cardField: {
      maxWidth: '300px',
      width: '300px',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      marginLeft: '8%',

      '@media (min-width:1150px)': {
        justifyContent: 'center',
      },
    },
    topPart: {
      background: 'white',
      boxShadow: '-4px 8px 13px rgba(0, 0, 0, 0.12)',
      backdropFilter: 'blur(40px)',
      borderRadius: '8px',
      paddingBottom: '42px',
    },
    title: {
      marginTop: '44px',
      fontWeight: '600',
      fontFamily: 'Roboto',
      fontSize: '43.4717px',
      lineHeight: '51px',
      paddingLeft: '47px',
    },
    subtitle: {
      marginTop: '10px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '125.19%',
      paddingLeft: '47px',
    },
    hello: {
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      fontSize: '28px',
      fontFamily: 'Roboto',
      paddingLeft: '40px',
      lineHeight: '137.19%',
      color: 'white',
      height: '110px',
      paddingBottom: '10px',
      background: greenColor,
    },
    whatYouWillFind: {
      color: '#484848',
      borderRadius: '16px',
      background: 'white',
      paddingBottom: '15px',
      paddingLeft: '30px',
      paddingTop: '30px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      marginTop: '-20px',
      zIndex: '2000',
    },
    infoOuter: {
      background: greenColor,
      width: '100%',
      height: '100%',
      padding: '25px',
      borderRadius: '15px',
      position: 'relative',
    },
    infoInner: {
      background: 'white',
      padding: '40px',
      borderRadius: '15px',
    },
    learnMore: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '5px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      background: 'white',
      width: '250px',
      height: '50px',
      boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '27px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '@media (max-width:600px)': {
        bottom: '-25px',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },
    pdf: {
      background: 'white',
      height: '200px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '40px',
      borderRadius: '15px',
      justifyContent: 'space-between',
    },
    downloadIcon: {
      width: '100px',
      height: '100px',
      left: '48px',
      top: '44px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      // padding: '30px',
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    categories: {
      borderRadius: '15px',
      padding: '30px',
      background: greenColor,
      display: 'flex',
      position: 'relative',
    },
    category: {
      color: 'white',
    },
    innerCategories: {
      background: 'white',
      width: '100%',
      padding: '70px 0',
      borderRadius: '15px',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      rowGap: '10px',
    },
    innerCategoriesMobile: {
      background: 'white',
      width: '100%',
      display: 'flex',
      gap: '30px',
      flexWrap: 'wrap',
      padding: '5px',
    },
  };
});

const Categories = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isSm: _1 } = useMediaQueries();

  return (
    <div className={ classes.categories }>
      <div className={ classes.innerCategories }>
        {
          iDNA3ToOneCards[1].fields.map((card) => {
            return (
              <div
                className={ clsx(classes.cardField, classes.smallText) }
                key={ card.text }
                aria-hidden='true'
                style={ { marginLeft: '0' } }
              >
                <Icon
                  className={ classes.cardIcon }
                  style={ { background: greenColor } }
                >
                  <img src={ card.icon } alt='' />
                </Icon>
                {t(card.text)}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

const VitaminsPage = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { serial: newSerial } = useOptionSerial('vitamins');

  const { data, isLoading } = useKits();

  const theKit = data?.find((kit) => { return kit.serial_number_id.serial === newSerial; });
  let hasPaid = false;
  if (theKit) {
    hasPaid = 'paid' === theKit?.serial_number_id?.report_payment_status;
  }

  const appointmentAvailability = data?.find((kit) => { return kit.serial_number_id.serial === newSerial; })?.appointment_availability;

  const { navigateToPage } = useNavigation();

  const { isSm } = useMediaQueries();

  const { userAttributes } = useAuth();

  if (!userAttributes || isLoading) {
    return null;
  }

  const {
    firstName,
  } = userAttributes;

  return (
    <div style={ { display: 'flex', flexDirection: 'column', gap: '20px' } }>
      <div className={ classes.topPart }>
        <div style={ { display: 'flex', justifyContent: 'space-between' } }>
          <div style={ { marginTop: '1rem' } }>
            <div className={ classes.title }>
              {t('Βιταμίνες & Ιχνοστοιχεία')}
            </div>
            <div className={ classes.subtitle }>
              Serial Number:
              {' '}
              {newSerial}
            </div>
          </div>
          <img
            src='/iDNA Genomics - Dynamic Report Homepage Vitamins - Banner.png'
            alt=''
            style={ { display: isSm ? 'none' : '' } }
          />
        </div>
        <div className={ classes.hello }>
          <AccountCircleIcon style={ { transform: 'scale(2.5)' } } />
          <div>
            {
              `${ t('hello') } ${ firstName },`
            }
          </div>
        </div>
        <div
          className={ classes.whatYouWillFind }
          style={ {
            lineHeight: '161.19%',
          } }
        >
          <div style={ { width: '72%' } }>
            Εδώ θα βρεις την Προσωπική σου Γενετική Ανάλυση (DNA Test)
            με βάση γονίδια που σχετίζονται με τις Βιταμίνες και τα Ιχνοστοιχεία.
            Η γνώση αυτή θα λειτουργήσει συμβουλευτικά για να προλάβουμε πιθανές ελλείψεις σε βιταμίνες και ιχνοστοιχεία με στόχο μία καλύτερη υγεία.
          </div>
        </div>
      </div>
      <Categories />
      {
        (hasPaid || true === appointmentAvailability)
          ? (
            <BottomPart
              pdfDownloadLink='nutritionPdfVersion'
              resultsPageLink='nutritionExplanationOfResults'
            />
          ) : (
            <Pdf navigateToPage={ navigateToPage } url='sportsPdfVersion' />
          )
      }
    </div>
  );
};

export default VitaminsPage;
