import SomethingWentWrongError from './SomethingWentWrong';
import InvalidCredentialsError from './InvalidCredentials';
import InvalidConfirmationCodeError from './InvalidConfirmationCode';
import AccountAlreadyExistsError from './AccountAlreadyExists';
import NotAuthenticatedError from './NotAuthenticated';
import InvalidSerialNumberError from './InvalidSerialNumber';
import DisabledError from './Disabled';
import InvalidCashStatusError from './InvalidCashStatus';

const SomethingWentWrong = new SomethingWentWrongError();
const InvalidCredentials = new InvalidCredentialsError();
const InvalidConfirmationCode = new InvalidConfirmationCodeError();
const AccountAlreadyExists = new AccountAlreadyExistsError();
const NotAuthenticated = new NotAuthenticatedError();
const InvalidSerialNumber = new InvalidSerialNumberError();
const InvalidCashStatusNumber = new InvalidCashStatusError();
const Disabled = new DisabledError();

const errors = {
  SomethingWentWrong,
  InvalidSerialNumber,
  InvalidCashStatusNumber,
  auth: {
    SomethingWentWrong,
    InvalidCredentials,
    InvalidConfirmationCode,
    AccountAlreadyExists,
    NotAuthenticated,
    Disabled,
  },
};

export default errors;
