import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Icon, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { BottomPartNutri, WellnessPdf } from 'pages/iDNA3ToOne/iDNA3ToOne';
import { useAuth } from 'AuthCtx';
import { useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/media-query-hooks';
import useAPI from 'hooks/api-hooks';
import { useQuery } from 'react-query';

export const blueColor = '#7C81C0';

const useStyles = makeStyles(() => {
  return {
    cardIcon: {
      height: '71px',
      width: '71px',
      display: 'flex',
      padding: '15px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
    },
    page: {
      display: 'flex',
      padding: '30px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      minHeight: '250px',
      borderRadius: '16px',
      flexWrap: 'nowrap',
      gap: '0',
      '@media (max-width:600px)': {
        flexWrap: 'wrap',
        gap: '8px',
      },
    },
    smallText: {
      fontWeight: '400',
      fontSize: '16px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
    },
    learnMoreArrow: {
      marginTop: '3px',
      transform: 'rotate(-45deg)',
    },
    cardField: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: '30px',
      maxWidth: '271px',
      boxshadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
    },
    topPart: {
      background: 'white',
      boxShadow: '-4px 8px 13px rgba(0, 0, 0, 0.12)',
      backdropFilter: 'blur(40px)',
      borderRadius: '8px',
      paddingBottom: '42px',
    },
    title: {
      marginTop: '44px',
      fontWeight: '600',
      fontFamily: 'Roboto',
      fontSize: '43.4717px',
      lineHeight: '51px',
      paddingLeft: '47px',
    },
    subtitle: {
      marginTop: '10px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '125.19%',
      paddingLeft: '47px',
    },
    hello: {
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      fontSize: '28px',
      fontFamily: 'Roboto',
      paddingLeft: '40px',
      lineHeight: '137.19%',
      color: 'white',
      height: '110px',
      paddingBottom: '10px',
      background: blueColor,
    },
    whatYouWillFind: {
      color: '#484848',
      borderRadius: '16px',
      background: 'white',
      paddingBottom: '15px',
      paddingLeft: '30px',
      paddingTop: '30px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      marginTop: '-20px',
      zIndex: '2000',
    },
    infoOuter: {
      background: blueColor,
      width: '100%',
      height: '100%',
      padding: '25px',
      borderRadius: '15px',
      position: 'relative',
    },
    infoInner: {
      background: 'white',
      padding: '40px',
      borderRadius: '15px',
    },
    learnMore: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '5px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      background: 'white',
      width: '250px',
      height: '50px',
      boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '27px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '@media (max-width:600px)': {
        bottom: '-25px',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },
    pdf: {
      background: 'white',
      height: '200px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '40px',
      borderRadius: '15px',
      justifyContent: 'space-between',
    },
    downloadIcon: {
      width: '100px',
      height: '100px',
      left: '48px',
      top: '44px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px',
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    categories: {
      borderRadius: '15px',
      padding: '30px',
      background: blueColor,
      display: 'flex',
      position: 'relative',
    },
    category: {
      color: 'white',
    },
    innerCategories: {
      background: 'white',
      width: '100%',
      padding: '70px 0 70px 25px',
      borderRadius: '15px',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'nowrap',
    },
    innerCategoriesMobile: {
      background: 'white',
      width: '100%',
      display: 'flex',
      gap: '30px',
      flexWrap: 'wrap',
      padding: '5px',
    },
  };
});

export const nutriGenetixCards = {
  color: blueColor,
  fields: [
    {
      icon: '/nutriUserIcon.png',
      text: 'Το προφίλ σου',
      nurl: 'nutriGenetixProfile',
    },
    {
      icon: '/nutriBook.png',
      text: 'Διατροφογενετική vs. Διατροφική κατάσταση',
      nurl: 'nutriGenetixDVD',
    },
    {
      icon: '/nutriChart.png',
      text: 'Εξατομικευμένο πλάνο διατροφής',
      nurl: 'nutriGenetixPlan',
    },
  ],
};

const Categories = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isSm } = useMediaQueries();

  return (
    <div className={ classes.categories }>
      <div className={ isSm ? classes.innerCategoriesMobile : classes.innerCategories }>
        {
          nutriGenetixCards.fields.map((card) => {
            return (
              <div
                className={ clsx(classes.cardField, classes.smallText) }
                key={ card.text }
                aria-hidden='true'
              >
                <Icon
                  className={ classes.cardIcon }
                  style={ { background: blueColor } }
                >
                  <img src={ card.icon } alt='' />
                </Icon>
                {t(card.text)}
              </div>
            );
          })
        }
      </div>
    </div>
  );
};

const NutriGenetix = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { serial: newSerial } = useOptionSerial('nutriGenetix');
  const { client } = useAPI();

  const { isSm } = useMediaQueries();

  const { userAttributes } = useAuth();

  const { navigateToPage } = useNavigation();

  const { data: bookedAppointments, isLoading: isBookedAppointmentsLoading } = useQuery(
    [ 'Appointment', 'booked', newSerial ], () => {
      return client.get('/appointments/getTimeSlots', { params: { serial_number: newSerial, serial_number_eq: newSerial } });
    },
  );

  const alreadyHadAppointment = null !== bookedAppointments && 0 < bookedAppointments?.length;

  if (!userAttributes || isBookedAppointmentsLoading) {
    return null;
  }

  const {
    firstName,
  } = userAttributes;

  return (
    <div style={ { display: 'flex', flexDirection: 'column', gap: '20px' } }>
      <div className={ classes.topPart }>
        <div style={ { display: 'flex', justifyContent: 'space-between' } }>
          <div style={ { marginTop: '1rem' } }>
            <div
              className={ classes.title }
            >
              iDNA NutriGenetix
            </div>
            <div className={ classes.subtitle }>
              Serial Number:
              {' '}
              {newSerial}
            </div>
          </div>
          <img
            src='/nutriGenetixBanner.png'
            alt=''
            style={ { display: isSm ? 'none' : '',
              marginRight: '10px' } }
          />
        </div>
        <div className={ classes.hello }>
          <AccountCircleIcon style={ { transform: 'scale(2.5)' } } />
          <div>
            {
              `${ t('hello') } ${ firstName },`
            }
          </div>
        </div>
        <div
          className={ classes.whatYouWillFind }
          style={ {
            lineHeight: '161.19%',
          } }
        >
          <div style={ { width: '72%' } }>
            <p style={ { fontWeight: 'bold' } }>
              {t('Στην παρούσα ανάλυση θα βρεις τα παρακάτω:')}
            </p>

            <ul>
              <li>
                {t('Το προφίλ σου, δηλαδή το σύνολο των φαινοτυπικών σου χαρακτηριστικών, όπως τα δήλωσες μέσα από το ερωτηματολόγιο που συμπλήρωσες.')}
              </li>
              <li>
                {t('Τις προσωπικές σου ανάγκες σε ενέργεια, μακροθρεπτικά και μικροθρεπτικά, όχι μόνο με βάση τη διατροφογενετική σου ανάλυση, αλλά έχοντας συνυπολογίσει το προφίλ σου, τον προσωπικό σου στόχο, αλλά και τις διατροφικές προτιμήσεις.')}
              </li>
              <li>
                {t('Το εξατομικευμένο σου εβδομαδιαίο πλάνο διατροφής συνδυάζονται όλες οι ανωτέρω πληροφορίες και ο προσωπικός σου στόχος υγείας (π.χ. απώλεια βάρους), με στόχο την πρόληψη εμφάνισης παθήσεων και τη συνολική υγεία.')}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Categories />
      {
        (!alreadyHadAppointment)
          ? (
            <BottomPartNutri
              resultsPageLink='nutrigenetixExplanationOfResults'
              buttonColor={ blueColor }
            />
          ) : (
            <WellnessPdf
              navigateToPage={ navigateToPage }
              url='nutriGenetixPdfVersion'
              buttonColor={ blueColor }
            />
          )
      }
    </div>
  );
};

export default NutriGenetix;
