/* eslint-disable prefer-const */
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import Grid from '@material-ui/core/Grid';
import { FormLabel, FormControl, FormControlLabel, Checkbox } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAPI from 'hooks/api-hooks';
import { useQuery, useQueryClient } from 'react-query';
import CountrySelectField, { countryNameToCode } from '../atoms/CountrySelectField';
import SimpleInputField from '../atoms/SimpleInputField';
import SimplePhoneField from '../atoms/SimplePhoneField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSection from '../atoms/FormSection';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import FormNames from '../atoms/form-names';
import { useAuth } from '../../../AuthCtx';
import { useDoctorSpecialties } from '../../../models/doctor-specialties';
import SimpleInputFieldWithValue from '../atoms/SimpleInputFieldWithValue';

const DoctorDetailsForm = (
  {
    onSubmit,
    onError,
  },
) => {
  const { t, i18n } = useTranslation();

  const { client } = useAPI();

  const { updateUserAttributes, userAttributes, userAttributesLoaded, userIsAuthenticated } = useAuth();

  const {
    firstName: initialFirstName,
    lastName: initialLastName,
    email: initialEmail,
    specialty: initialSpecialtyName,
    businessNumber: initialBusinessNumber,
    mobileNumber: initialMobileNumber,
    streetAddress: initialStreetAddress,
    postalCode: initialPostalCode,
    city: initialCity,
    country: initialCountry,
    brandName: initialBrandName,
  } = userAttributes || {};

  const [ tmpCountry, setTmpCountry ] = useState(initialCountry);
  useEffect(() => {
    setTmpCountry(initialCountry);
  }, [ initialCountry ]);

  const [ isSubmitting, setSubmitting ] = useState(false);

  const { data: doctorData, isLoading: doctorDataLoading } = useQuery([ 'doctors', 'me', 'backend', `${ userIsAuthenticated }` ], () => {
    if (!userIsAuthenticated) {
      return undefined;
    }
    return client.get('doctors/me');
  });

  const { data: hospitals, isLoading: hospitalsLoading } = useQuery([ 'hospitals' ], () => {
    if (!userIsAuthenticated) {
      return undefined;
    }
    return client.get('hospitals?_limit=-1&_sort=name').then((data) => {
      let copy = _.cloneDeep(data);

      copy.sort((a, b) => {
        return a.health_district - b.health_district || a.name.localeCompare(b.name);
      });

      copy = copy.map((hosp) => {
        return {
          ...hosp,
          health_district: `${ hosp.health_district }η Υγειονομική Περιφέρεια`,
          name: hosp.name.toUpperCase(),
        };
      });

      return copy;
    });
  });

  const [ docHospital, setDocHospital ] = useState(doctorData?.hospital ?? {});
  const [ docHospitalError, setDocHospitalError ] = useState(false);

  const initialAFM = doctorData?.tax_identification_number ?? '';

  const [ pshp, setPshp ] = useState(doctorData?.public_sector_hp ?? false);
  const [ addressComment, setAddressComment ] = useState(doctorData?.address_description ?? '');

  useEffect(() => {
    if (false === pshp) {
      setAddressComment('');
      setDocHospital({});
    }
  }, [ pshp ]);

  const updateHospitalAddress = useCallback((hosp) => {
    if (hosp && !_.isEmpty(hosp)) {
      setStreetAddressField(hosp.address);
      setPostalCodeField(hosp.postal_code);
      setCityField(hosp.region);
      setTmpCountry('GR');
    }
  }, []);

  useEffect(() => {
    if (doctorData) {
      setPshp(doctorData?.public_sector_hp ?? false);
      if (doctorData?.hospital) {
        const hosp = _.cloneDeep(doctorData?.hospital);
        hosp.name = doctorData.hospital.name.toUpperCase();
        setDocHospital(hosp);
      } else {
        setDocHospital(doctorData?.hospital ?? {});
      }

      setAddressComment(doctorData.address_description ?? '');
    }
  }, [ doctorDataLoading ]);

  const {
    requiredFieldRule,
    phoneNumberRule,
    requiredPhoneRule,
    atMost10Rule,
    nineCharsRule,
  } = useFormRules();

  const [
    errorAFM,
    helperTextAFM,
    validateAFM,
  ] = useInputValidation(
    [
      nineCharsRule,
    ],
  );

  const [
    errorFirstName,
    helperTextFirstName,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [ specialty, setSpecialty ] = useState(null);
  const [ specialtyError, specialtyHelperText, validateSpecialty ] = useInputValidation(
    [ requiredFieldRule ],
  );

  const [ streetAddressField, setStreetAddressField ] = useState(initialStreetAddress ?? '');
  const [ postalCodeField, setPostalCodeField ] = useState(initialPostalCode ?? '');
  const [ cityField, setCityField ] = useState(initialCity ?? '');

  const {
    data: specialties,
    isLoading: specialtiesLoading,
  } = useDoctorSpecialties();

  const sortedSpecialties = useMemo(() => {
    const temp = specialties?.map((spec) => {
      // eslint-disable-next-line prefer-template
      const tname = t('common.specialties.' + spec.name);

      return {
        ...spec,
        translated_name: tname,
      };
    });

    return _.sortBy(temp, 'translated_name');
  }, [ specialties, i18n.language ]);

  useEffect(() => {
    if (!specialties) {
      return;
    }
    setSpecialty(specialties.find((s) => { return s.name === initialSpecialtyName; }));
  }, [ initialSpecialtyName, specialties, userAttributesLoaded ]);

  useEffect(() => {
    if (null == specialty || '' === specialty) {
      return;
    }

    const prevSpec = _.cloneDeep(specialty);
    setSpecialty('');
    setSpecialty(prevSpec);
  }, [ i18n.language, specialtiesLoading, userAttributesLoaded ]);

  const [
    errorStreetAddress,
    helperTextStreetAddress,
    validateStreetAddress,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorPostalCode,
    helperTextPostalCode,
    validatePostalCode,
  ] = useInputValidation(
    [
      requiredFieldRule,
      atMost10Rule,
    ],
  );

  const [
    errorCity,
    helperTextCity,
    validateCity,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorCountry,
    helperTextCountry,
    validateCountry,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorMobileNumber,
    helperTextMobileNumber,
    validateMobileNumber,
  ] = useInputValidation(
    [
      phoneNumberRule,
    ],
  );

  const [
    errorBusinessNumber,
    helperTextBusinessNumber,
    validateBusinessNumber,
  ] = useInputValidation(
    [
      requiredPhoneRule,
      phoneNumberRule,
    ],
  );

  const queryClient = useQueryClient();

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    let {
      [FormNames.firstName]: firstName,
      [FormNames.lastName]: lastName,
      // [FormNames.specialty]: specialty, this is not used since autocomplete component works a little bit different
      [FormNames.streetAddress]: streetAddress,
      [FormNames.postalCode]: postalCode,
      [FormNames.city]: city,
      [FormNames.country]: country,
      [FormNames.mobileNumber]: mobileNumber,
      [FormNames.countryMobileNumber]: countryMobileNumber,
      [FormNames.businessNumber]: businessNumber,
      [FormNames.countryBusinessNumber]: countryBusinessNumber,
      [FormNames.brandName]: brandName,
      afm,
    } = Object.fromEntries(formData);

    country = countryNameToCode(country);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateFirstName(firstName); },
      () => { return validateLastName(lastName); },
      () => { return validateSpecialty(specialty?.name); },
      () => {
        if ('ΔΙΑΙΤΟΛΟΓΙΑ - ΔΙΑΤΡΟΦΟΛΟΓΙΑ' === specialty.name) {
          return validateAFM(afm);
        }

        return validateAFM(afm, [ nineCharsRule ]);
      },
      () => {
        if (pshp && _.isEmpty(docHospital)) {
          setDocHospitalError(true);
          return false;
        }

        setDocHospitalError(false);
        return true;
      },
      () => { return validateStreetAddress(streetAddress); },
      () => { return validatePostalCode(postalCode); },
      () => { return validateCity(city); },
      () => { return validateCountry(country); },
      () => {
        return validateMobileNumber(
          {
            phoneNumber: mobileNumber,
            country: countryMobileNumber,
          },
        );
      },
      () => {
        return validateBusinessNumber(
          {
            phoneNumber: businessNumber,
            country: countryBusinessNumber,
          },
        );
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return updateUserAttributes({
      firstName: firstName?.toUpperCase(),
      lastName: lastName?.toUpperCase(),
      specialty: specialty.name,
      streetAddress: streetAddress?.toUpperCase(),
      postalCode,
      country,
      city: city.toUpperCase(),
      mobileNumber,
      businessNumber,
      brandName: brandName?.toUpperCase(),
    })
      .then(() => {
        return onSubmit({
          firstName: firstName?.toUpperCase(),
          lastName: lastName?.toUpperCase(),
          specialty,
          streetAddress: streetAddress?.toUpperCase(),
          postalCode,
          city: city?.toUpperCase(),
          mobileNumber,
          businessNumber,
        });
      })
      .then(() => {
        client.put('doctors/me/details',
          {
            name: firstName?.toUpperCase(),
            family_name: lastName?.toUpperCase(),
            phone_number: mobileNumber,
            specialty: specialty.name,
            business_number: businessNumber,
            brand_name: brandName?.toUpperCase(),
            address: JSON.stringify({
              streetAddress: streetAddress?.toUpperCase(),
              postalCode,
              city: city?.toUpperCase(),
            }),
            hospital: docHospital && pshp ? docHospital.id : null,
            country: country?.toUpperCase(),
            tax_identification_number: afm,
            public_sector_hp: pshp,
            address_description: addressComment,
          }).then(() => {
          queryClient.invalidateQueries([ 'doctors', 'me', 'backend', 'true' ]);
        });
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (!userAttributesLoaded || doctorDataLoading) {
    return null;
  }

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
      style={ {
        padding: '24px',
      } }
    >
      <FormSection label={ t('Προσωπικά στοιχεία ιατρού') } color='#526D9C'>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('firstName') }
                  lettersOnly
                  upperCase
                  autoComplete='given-name'
                  defaultValue={ initialFirstName }
                  validate={ validateFirstName }
                  name={ FormNames.firstName }
                  required
                  error={ errorFirstName }
                  helperText={ helperTextFirstName }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('lastName') }
                  defaultValue={ initialLastName }
                  upperCase
                  lettersOnly
                  autoComplete='family-name'
                  validate={ validateLastName }
                  name={ FormNames.lastName }
                  required
                  error={ errorLastName }
                  helperText={ helperTextLastName }
                />
              </Grid>

              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('brandName') }
                  upperCase
                  defaultValue={ initialBrandName }
                  autoComplete='brand-name'
                  name={ FormNames.brandName }
                />
              </Grid>

              <Grid item xs={ 12 } md={ 6 }>
                <FormControl size='small' fullWidth>
                  <FormLabel color='secondary' component='legend' required>
                    {t('specialty')}
                  </FormLabel>
                </FormControl>
                <Autocomplete
                  getOptionSelected={ (option, v) => {
                    if (!v) { return null; }
                    return option.id === v.id;
                  } }
                  getOptionLabel={ (option) => { return t(option.name) || ''; } }
                  options={ sortedSpecialties || [] }
                  loading={ specialtiesLoading }
                  value={ specialty }
                  onChange={ (e, v) => {
                    setSpecialty(v);
                  } }
                  renderInput={ (params) => {
                    return (
                      <TextField
                        { ...params }
                        placeholder={ `${ t('selectYourDomain') }...` }
                        variant='outlined'
                        color='secondary'
                        size='small'
                        error={ specialtyError && null == specialty }
                        helperText={ specialtyError && null == specialty ? t(specialtyHelperText) : null }
                        style={ { marginBottom: '8px' } }
                        InputProps={ {
                          ...params.InputProps,
                          style: { textTransform: 'uppercase' },
                          endAdornment: (
                            <>
                              {specialtiesLoading ? (
                                <CircularProgress
                                  color='inherit'
                                  size={ 20 }
                                />
                              ) : null}
                              {params.InputProps.endAdornment}
                            </>
                          ),
                        } }
                      />
                    );
                  } }
                />
              </Grid>

              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('afm') }
                  defaultValue={ initialAFM }
                  autoComplete='afm'
                  name='afm'
                  error={ errorAFM }
                  helperText={ helperTextAFM }
                />
              </Grid>

              {
                'GR' === tmpCountry && false === hospitalsLoading && hospitals && (
                  <Grid container item xs={ 12 } spacing={ 2 } style={ { minHeight: '91px' } }>
                    <Grid
                      item
                      xs={ 12 }
                      md={ 6 }
                      style={ { display: 'flex', alignItems: 'center' } }
                    >
                      <FormControlLabel
                        style={ { marginLeft: '0' } }
                        control={ (
                          <Checkbox
                            checked={ pshp }
                            onChange={ (e) => {
                              setPshp(e.target.checked);
                            } }
                          />
                        ) }
                        label={ t('Επαγγελματίας Υγείας Δημόσιου Φορέα') }
                        labelPlacement='start'
                      />
                    </Grid>

                    {
                      pshp && (
                        <>
                          <Grid item xs={ 12 } md={ 6 }>
                            <FormLabel
                              color='secondary'
                              component='legend'
                              error={ docHospitalError }
                              style={ { marginBottom: '3px' } }
                            >
                              {t('Νοσοκομείο')}
                              {' *'}
                            </FormLabel>

                            <Autocomplete
                              options={ hospitals }
                              groupBy={ (option) => { return option.health_district; } }
                              getOptionLabel={ (option) => { return option.name; } }
                              color='secondary'
                              error={ docHospitalError }
                              value={ docHospital }
                              onChange={ (e, newValue) => {
                                setDocHospital(newValue);
                                updateHospitalAddress(newValue);
                              } }
                              size='small'
                              fullWidth
                              renderInput={ (params) => {
                                return (
                                  <TextField
                                    { ...params }
                                    color='secondary'
                                    error={ docHospitalError }
                                    helperText={ docHospitalError && t('validation.requiredField') }
                                    required
                                    variant='outlined'
                                  />
                                );
                              } }
                            />
                          </Grid>
                        </>
                      )
                    }
                  </Grid>
                )
              }

            </Grid>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label={ t('physicalAddress') } color='#526D9C'>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 8 }>
                <SimpleInputFieldWithValue
                  label={ t('streetAddress') }
                  upperCase
                  autoComplete='street-address'
                  defaultValue={ initialStreetAddress }
                  name={ FormNames.streetAddress }
                  required
                  error={ errorStreetAddress }
                  helperText={ helperTextStreetAddress }
                  value={ streetAddressField }
                  setValue={ (newVal) => { setStreetAddressField(newVal); } }
                />
              </Grid>

              <Grid item xs={ 4 }>
                <SimpleInputFieldWithValue
                  label={ t('postalCode') }
                  defaultValue={ initialPostalCode }
                  autoComplete='postal-code'
                  name={ FormNames.postalCode }
                  required
                  error={ errorPostalCode }
                  helperText={ helperTextPostalCode }
                  value={ postalCodeField }
                  setValue={ (newVal) => { setPostalCodeField(newVal); } }
                />
              </Grid>
              <Grid item container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 8 }>
                  <SimpleInputFieldWithValue
                    label={ t('cityOrTown') }
                    defaultValue={ initialCity }
                    name={ FormNames.city }
                    upperCase
                    required
                    error={ errorCity }
                    helperText={ helperTextCity }
                    value={ cityField }
                    setValue={ (newVal) => { setCityField(newVal); } }
                  />
                </Grid>

                <Grid item xs={ 12 } sm={ 4 }>
                  <CountrySelectField
                    label={ t('country') }
                    defaultValue={ initialCountry }
                    name={ FormNames.country }
                    required
                    error={ errorCountry }
                    helperText={ helperTextCountry }
                    validate={ validateCountry }
                    onChange={ (newVal) => {
                      setTmpCountry(newVal);
                    } }
                  />
                </Grid>

                {
                  pshp && 'GR' === tmpCountry && (
                    <Grid item xs={ 12 }>
                      <FormLabel
                        color='secondary'
                        component='legend'
                        style={ { marginBottom: '3px' } }
                      >
                        {t('Σχόλια (προσθέστε επιπλέον πληροφορίες σχετικά με το γραφείο σας εντός του νοσοκομείου που εργάζεστε)')}
                      </FormLabel>

                      <TextField
                        multiline
                        fullWidth
                        color='secondary'
                        variant='outlined'
                        value={ addressComment }
                        onChange={ (e) => {
                          if (e.target.value) {
                            setAddressComment(e.target.value.toUpperCase());
                          }
                          setAddressComment(e.target.value);
                        } }
                        minRows={ 1 }
                        maxRows={ Infinity }
                        inputProps={ { style: { textTransform: 'uppercase' } } }
                      />
                    </Grid>
                  )
                }
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </FormSection>
      <FormSection label={ t('contactDetails') } color='#526D9C'>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <SimpleInputField
              name={ FormNames.email }
              label={ t('email') }
              required
              defaultValue={ initialEmail }
              readOnly
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimplePhoneField
              name={ FormNames.businessNumber }
              defaultValue={ initialBusinessNumber }
              autoComplete='tel-national'
              required
              label={ t('businessNumber') }
              defaultCountryValue={ initialCountry ?? 'GR' }
              countrySelectName={ FormNames.countryBusinessNumber }
              error={ errorBusinessNumber }
              helperText={ helperTextBusinessNumber }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimplePhoneField
              name={ FormNames.mobileNumber }
              label={ t('mobileNumber') }
              defaultValue={ initialMobileNumber }
              autoComplete='tel-national'
              defaultCountryValue={ initialCountry ?? 'GR' }
              countrySelectName={ FormNames.countryMobileNumber }
              error={ errorMobileNumber }
              helperText={ helperTextMobileNumber }
            />
          </Grid>
        </Grid>
      </FormSection>
      <FormSectionSubmit
        color='#526D9C'
        label={ t('save') }
        loading={ isSubmitting }
      />

    </form>
  );
};

DoctorDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

DoctorDetailsForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default DoctorDetailsForm;
