import React, { useEffect, useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import FormSection from '../atoms/FormSection';
import SimpleCheckbox from '../atoms/SimpleCheckbox';
import { useInputValidation } from '../../../hooks/form-rules';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import SimpleRadioInlineRequired from '../atoms/SimpleRadioInlineRequired';
import { bioAgeConsents, generalConsents, marketingConsents, wellnessConsents } from '../../../models/agreements';
import { useKit } from '../../../models/kits';

const GeneralConsentWellnessForm = (
  {
    kitId,
    onSubmit,
    onError,
    goToPrevPage,
  },
) => {
  const { t, i18n } = useTranslation();

  const { isLoading, upsertAgreement, kitAgreements, isKitNutri, isKitWellness,
    isKitBioAge, setFormGuardian, guardian, child, isKitLabs } = useKit(kitId);

  const [ parentName, setParentName ] = useState('');

  const [ initialConsentsValues, setInitialConsentsValues ] = useState({
    [wellnessConsents.NUTRIGENETIX_CARDIO_WELLNESS_GENERAL_CONSENT]: null,
    [generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]: null,
    [marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT]: null,
    [generalConsents.GDPR_CONSENT]: null,
    [generalConsents.LEGAL_GUARDIAN_CONSENT]: null,
    [wellnessConsents.GENETIC_ANALYSIS_CONSENT]: null,
    [bioAgeConsents.BIOLOGICAL_AGE_GENERAL_CONSENT]: null,
    [bioAgeConsents.BIO_DATA_CONSENT]: null,
  });

  const gdprRef = useRef(null);
  const dataRef = useRef(null);
  const firstRadioRef = useRef(null);
  const secondRadioRef = useRef(null);

  const setConsentValue = (consentName, consentValue) => {
    if (Object.keys(initialConsentsValues).includes(consentName)) {
      setInitialConsentsValues((prevValues) => {
        return {
          ...prevValues,
          [consentName]: consentValue,
        };
      });
    }
  };

  useEffect(() => {
    if ('' === parentName && null != guardian) {
      setParentName(guardian);
    }
  }, [ guardian ]);

  useEffect(() => {
    if (!isLoading && kitAgreements) {
      setInitialConsentsValues((prevValues) => {
        const updatedAgreements = Object.keys(initialConsentsValues)
          .reduce((acc, consentName) => {
            return {
              ...acc,
              [consentName]: 'boolean' === typeof kitAgreements[consentName] ? kitAgreements[consentName] : null,
            };
          }, {});
        if (_.isEqual(prevValues, updatedAgreements)) {
          return prevValues;
        }
        return updatedAgreements;
      });
    }
  }, [ isLoading, kitAgreements ]);

  const [ isSubmitting, setSubmitting ] = useState(false);

  const [ agreeError, agreeHelperText, validateAgree ] = useInputValidation(
    [ (v) => { return 'yes' === v || 'reg_kit.consents.common.required_consent'; } ],
  );

  const [ bioagreeError, bioagreeHelperText, biovalidateAgree ] = useInputValidation(
    [ (v) => { return 'yes' === v || 'reg_kit.consents.common.required_consent'; } ],
  );

  const [ agreeExtraError, aggreeExtraHelperText, validateAgreeExtra ] = useInputValidation(
    [ (v) => { return 'yes' === v || 'reg_kit.consents.common.required_consent'; } ],
  );

  const [ parentConsentError, parentConsentHelperText, validateParentConsent ] = useInputValidation(
    [ (v) => { return 'yes' === v || 'reg_kit.consents.common.required_consent'; } ],
  );

  // const [ bioagreeExtraError, bioaggreeExtraHelperText, biovalidateAgreeExtra ] = useInputValidation(
  //   [ (v) => { return 'yes' === v || 'reg_kit.consents.common.required_consent'; } ],
  // );

  const [ errorDNA, helperTextDNA, validateDNA ] = useInputValidation(
    [ (v) => { return [ 'yes', 'no' ].includes(v) || 'reg_kit.consents.common.required_selection'; } ],
  );

  const [ errorInformativeMaterial, helperInformativeMaterial, validateInformativeMaterial ] = useInputValidation(
    [ (v) => { return [ 'yes', 'no' ].includes(v) || 'reg_kit.consents.common.required_selection'; } ],
  );

  const handleSubmitBio = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      [bioAgeConsents.BIOLOGICAL_AGE_GENERAL_CONSENT]: bioAgeGeneral,
      [bioAgeConsents.BIO_DATA_CONSENT]: wellnessGenetic,
      [generalConsents.ANONYMOUS_REPORT_RESEARCH_PURPOSE_CONSENT]: anonymousReportResearchConsent,
      [marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT]: informativeMaterialConsent,
      // [wellnessConsents.GENETIC_ANALYSIS_CONSENT]: wellnessGenetic,
    } = Object.fromEntries(formData);

    if (![
      () => {
        const value = biovalidateAgree(bioAgeGeneral);
        if (!value) {
          gdprRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      // () => {
      //   const value = biovalidateAgreeExtra(bioAgeData);
      //   if (!value) {
      //     dataRef.current.scrollIntoView({ block: 'center' });
      //   }
      //   return value;
      // },
      () => {
        const value = validateAgreeExtra(wellnessGenetic);
        if (!value) {
          dataRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      () => {
        const value = validateDNA(anonymousReportResearchConsent);
        if (!value) {
          firstRadioRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      () => {
        const value = validateInformativeMaterial(informativeMaterialConsent);
        if (!value) {
          secondRadioRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }
    return Promise.resolve()
      .then(() => {
        const upsertAgreements = [
          {
            consentName: generalConsents.ANONYMOUS_REPORT_RESEARCH_PURPOSE_CONSENT,
            status: 'yes' === anonymousReportResearchConsent,
          },
          {
            consentName: marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT,
            status: 'yes' === informativeMaterialConsent,
          },
          {
            consentName: bioAgeConsents.BIOLOGICAL_AGE_GENERAL_CONSENT,
            status: 'yes' === bioAgeGeneral,
          },
          {
            consentName: bioAgeConsents.BIO_DATA_CONSENT,
            status: 'yes' === wellnessGenetic,
          },
        ].map((d) => {
          return Promise.resolve().then(() => {
            return upsertAgreement(d);
          });
        });
        return Promise.all(upsertAgreements);
      })
      .then((data) => {
        return onSubmit(data);
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      [wellnessConsents.NUTRIGENETIX_CARDIO_WELLNESS_GENERAL_CONSENT]: wellnessGeneralConsent,
      [generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]: anonymousDNAResearchPurposeConsent,
      [marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT]: informativeMaterialConsent,
      [generalConsents.GDPR_CONSENT]: gdprConsent,
      [generalConsents.LEGAL_GUARDIAN_CONSENT]: legalGuardianConsent,
      [wellnessConsents.GENETIC_ANALYSIS_CONSENT]: wellnessGenetic,
    } = Object.fromEntries(formData);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => {
        const value = validateAgree(wellnessGeneralConsent);
        if (!value) {
          gdprRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      () => {
        const value = validateAgreeExtra(wellnessGenetic);
        if (!value) {
          dataRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      () => {
        const value = validateDNA(anonymousDNAResearchPurposeConsent);
        if (!value) {
          firstRadioRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      () => {
        const value = validateInformativeMaterial(informativeMaterialConsent);
        if (!value) {
          secondRadioRef.current.scrollIntoView({ block: 'center' });
        }
        return value;
      },
      () => {
        if (null != child) {
          return validateParentConsent(legalGuardianConsent);
        }

        return true;
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        const upsertAgreements = [
          {
            consentName: wellnessConsents.NUTRIGENETIX_CARDIO_WELLNESS_GENERAL_CONSENT,
            status: 'yes' === wellnessGeneralConsent,
          },
          {
            consentName: generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT,
            status: 'yes' === anonymousDNAResearchPurposeConsent,
          },
          {
            consentName: marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT,
            status: 'yes' === informativeMaterialConsent,
          },
          {
            consentName: generalConsents.GDPR_CONSENT,
            status: 'yes' === gdprConsent,
          },
          {
            consentName: generalConsents.LEGAL_GUARDIAN_CONSENT,
            status: 'yes' === legalGuardianConsent,
          },
          {
            consentName: wellnessConsents.GENETIC_ANALYSIS_CONSENT,
            status: 'yes' === wellnessGenetic,
          },
        ].map((d) => {
          return Promise.resolve().then(() => {
            return upsertAgreement(d);
          });
        });
        return Promise.all(upsertAgreements);
      })
      .then((data) => {
        return onSubmit(data);
      })
      .then(() => {
        setFormGuardian(parentName);
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  let consentStatements; //  isKitNutri ? [ 'inform', 'proceed', 'destroy', 'trueAndAccurate' ] : [ 'inform', 'proceed', 'trueAndAccurate' ];

  if (isKitNutri) {
    if (null == child) {
      if (isKitLabs) {
        consentStatements = [ 'common.informed_analysis_purpose_labs', 'common.proceed_analysis', 'adult.repeat_analysis', 'adult.real_info' ];
      } else {
        consentStatements = [ 'common.informed_analysis_purpose', 'common.proceed_analysis', 'adult.repeat_analysis', 'adult.real_info' ];
      }
    } else {
      consentStatements = [ 'common.informed_analysis_purpose', 'common.proceed_analysis', 'child.repeat_analysis', 'child.real_info' ];
    }
  } else if (isKitWellness) {
    if (null == child) {
      consentStatements = [ 'common.informed_analysis_purpose', 'common.proceed_analysis', 'adult.repeat_analysis', 'adult.real_info' ];
    } else {
      consentStatements = [ 'common.informed_analysis_purpose', 'common.proceed_analysis', 'child.repeat_analysis', 'child.real_info' ];
    }
  } else if (isKitBioAge) {
    consentStatements = [ 'informed_analysis_purpose', 'proceed_analysis', 'real_info' ];
  }

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <form
      noValidate
      onSubmit={ isKitBioAge ? handleSubmitBio : handleSubmit }
    >
      <Grid
        container
        style={ {
          padding: '24px 24px 0 24px',
        } }
      >
        <FormSection label={ t('consentStatement') }>

          <Typography variant='body1' style={ { marginTop: '-10px', marginBottom: '10px' } }>
            {
              isKitBioAge ? t('reg_kit.consents.bioage.consent_category')
                : t('reg_kit.consents.non_pgx_non_bioage.consent_category')
            }
          </Typography>

          <Grid container spacing={ 3 }>
            <Grid item md={ 8 } xs={ 12 }>
              {
                consentStatements.map((fact) => {
                  return (
                    <Grid key={ fact } item>
                      <Typography
                        variant='body2'
                        style={ {
                          textAlign: 'initial',
                          marginBottom: '16px',
                        } }
                      >
                        {!isKitBioAge && null == child && t(`reg_kit.consents.non_pgx_non_bioage.${ fact }`)}
                        {null != child && t(`reg_kit.consents.non_pgx_non_bioage.${ fact }`)}
                        {isKitBioAge && t(`reg_kit.consents.bioage.${ fact }`)}
                      </Typography>
                    </Grid>
                  );
                })
              }
            </Grid>
            <Grid
              item
              ref={ gdprRef }
              md={ 4 }
              xs={ 12 }
              style={ {
                marginLeft: 'auto',
                marginTop: 'auto',
              } }
            >
              {isKitBioAge ? (
                <SimpleCheckbox
                  required
                  label={ t('common.agree') }
                  name={ bioAgeConsents.BIOLOGICAL_AGE_GENERAL_CONSENT }
                  formStyle={ { display: 'flex', alignItems: 'flex-end', justifyContent: 'center' } }
                  labelStyle={ { marginLeft: 'auto' } }
                  value={ initialConsentsValues[bioAgeConsents.BIOLOGICAL_AGE_GENERAL_CONSENT] }
                  onChange={ (v) => {
                    setConsentValue(bioAgeConsents.BIOLOGICAL_AGE_GENERAL_CONSENT, v);
                    biovalidateAgree(true === v ? 'yes' : 'no');
                  } }
                  error={ bioagreeError }
                  helperText={ bioagreeHelperText }
                />
              ) : (
                <SimpleCheckbox
                  required
                  label={ t('common.agree') }
                  labelStyle={ { marginLeft: 'auto' } }
                  name={ wellnessConsents.NUTRIGENETIX_CARDIO_WELLNESS_GENERAL_CONSENT }
                  formStyle={ { display: 'flex', alignItems: 'flex-end', justifyContent: 'center' } }
                  value={ initialConsentsValues[wellnessConsents.NUTRIGENETIX_CARDIO_WELLNESS_GENERAL_CONSENT] }
                  onChange={ (v) => {
                    setConsentValue(wellnessConsents.NUTRIGENETIX_CARDIO_WELLNESS_GENERAL_CONSENT, v);
                    validateAgree(true === v ? 'yes' : 'no');
                  } }
                  error={ agreeError }
                  helperText={ agreeHelperText }
                />
              ) }

            </Grid>
          </Grid>

          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <Typography
                color='secondary'
                variant='h6'
                style={ { marginLeft: '0', width: '100%' } }
              >
                {t('reg_kit.consents.common.consent_personal_data_title')}
              </Typography>
              <Divider style={ { width: '66%' } } />
            </Grid>

            {
              'en-US' === i18n.language && (
                <Grid item xs={ 12 }>
                  <Typography
                    variant='body1'
                    style={ { marginLeft: '0', width: '100%' } }
                  >
                    {t('reg_kit.consents.non_doctor.consent_to_following')}
                  </Typography>
                </Grid>
              )
            }

            <Grid item md={ 8 } xs={ 12 }>
              <ul style={ { marginTop: '0', marginBottom: '8px' } }>
                <li>
                  <Typography variant='body2' style={ { textAlign: 'initial' } }>
                    {/* {isKitBioAge && t('consents.bioAge.extra')}
                {isKitWellness && t('consents.wellness.extra')}
                {isKitNutri && t('consents.wellness.extra')} */}
                    {/* {t('gdprConsent')} */}
                    {isKitBioAge
                      ? t('reg_kit.consents.adult.bioage.process_personal_data')
                      : null == child
                        ? isKitLabs
                          ? t('reg_kit.consents.adult.non_pgx_non_bioage.process_personal_data_labs')
                          : t('reg_kit.consents.adult.non_pgx_non_bioage.process_personal_data')
                        : t('reg_kit.consents.child.process_personal_data')}
                  </Typography>
                </li>
              </ul>
            </Grid>

            <Grid item ref={ dataRef } md={ 4 } xs={ 12 }>
              {isKitBioAge ? (
                <SimpleCheckbox
                  required
                  label={ t('common.agree') }
                  name={ bioAgeConsents.BIO_DATA_CONSENT }
                  formStyle={ { display: 'flex', alignItems: 'flex-end', justifyContent: 'center' } }
                  value={ initialConsentsValues[bioAgeConsents.BIO_DATA_CONSENT] }
                  onChange={ (v) => {
                    setConsentValue(bioAgeConsents.BIO_DATA_CONSENT, v);
                    validateAgreeExtra(true === v ? 'yes' : 'no');
                  } }
                  error={ agreeExtraError }
                  helperText={ aggreeExtraHelperText }
                />
              ) : (
                <SimpleCheckbox
                  required
                  label={ t('common.agree') }
                  formStyle={ { display: 'flex', alignItems: 'flex-end', justifyContent: 'center' } }
                  name={ wellnessConsents.GENETIC_ANALYSIS_CONSENT }
                  value={ initialConsentsValues[wellnessConsents.GENETIC_ANALYSIS_CONSENT] }
                  onChange={ (v) => {
                    setConsentValue(wellnessConsents.GENETIC_ANALYSIS_CONSENT, v);
                    validateAgreeExtra(true === v ? 'yes' : 'no');
                  } }
                  error={ agreeExtraError }
                  helperText={ aggreeExtraHelperText }
                />
              ) }

            </Grid>
          </Grid>

          <Typography style={ { fontStyle: 'italic', marginLeft: '40px' } } variant='body2'>
            {t('reg_kit.consents.common.unable_to_process_warning')}
          </Typography>

          <Grid item container spacing={ 3 } alignItems='center' style={ { marginTop: '10px' } }>
            <Grid item xs={ 12 } md={ 8 }>
              <ul style={ { marginTop: '0' } }>
                <li>
                  <Typography variant='body2' style={ { textAlign: 'initial' } }>
                    {isKitBioAge && `${ t('reg_kit.consents.adult.bioage.anonymized_data_consent') }*`}
                    {!isKitBioAge && (
                      <>
                        {
                          null == child
                            ? `${ t('reg_kit.consents.adult.non_bioage.anonymized_data_consent') }*`
                            : `${ t('reg_kit.consents.child.anonymized_data_consent') }*`
                        }
                      </>
                    )}
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              xs={ 12 }
              md={ 4 }
              style={ { textAlign: 'right', paddingTop: 0 } }
              ref={ firstRadioRef }
            >
              {isKitBioAge ? (
                <SimpleRadioInlineRequired
                  required
                  label=''
                  name={ generalConsents.ANONYMOUS_REPORT_RESEARCH_PURPOSE_CONSENT }
                  defaultValue={ null !== initialConsentsValues[generalConsents.ANONYMOUS_REPORT_RESEARCH_PURPOSE_CONSENT]
                    ? (initialConsentsValues[generalConsents.ANONYMOUS_REPORT_RESEARCH_PURPOSE_CONSENT] ? 'yes' : 'no') : null }
                  options={ [
                    {
                      label: t('common.yes'),
                      value: 'yes',
                    },
                    {
                      label: t('common.no'),
                      value: 'no',
                    },
                  ] }
                  error={ errorDNA }
                  helperText={ helperTextDNA }
                  validate={ validateDNA }
                />
              ) : (
                <SimpleRadioInlineRequired
                  required
                  label=''
                  name={ generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT }
                  defaultValue={ null !== initialConsentsValues[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]
                    ? (initialConsentsValues[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT] ? 'yes' : 'no') : null }
                  options={ [
                    {
                      label: t('common.yes'),
                      value: 'yes',
                    },
                    {
                      label: t('common.no'),
                      value: 'no',
                    },
                  ] }
                  error={ errorDNA }
                  helperText={ helperTextDNA }
                  validate={ validateDNA }
                />
              )}

            </Grid>
          </Grid>
          <Grid item container spacing={ 3 } alignItems='center'>
            <Grid item xs={ 12 } md={ 8 }>
              <ul style={ { marginTop: '0' } }>
                <li>
                  <Typography variant='body2' style={ { textAlign: 'initial' } }>
                    {
                      isKitLabs
                        ? t('reg_kit.consents.non_doctor.non_pgx.advertized_content_consent_labs')
                        : t('reg_kit.consents.non_doctor.non_pgx.advertized_content_consent')
                    }
                    {'*'}
                  </Typography>
                </li>
              </ul>
            </Grid>
            <Grid
              item
              xs={ 12 }
              md={ 4 }
              style={ { textAlign: 'right', paddingTop: 0 } }
              ref={ secondRadioRef }
            >
              <SimpleRadioInlineRequired
                required
                label=''
                name={ marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT }
                options={ [
                  {
                    label: t('common.yes'),
                    value: 'yes',
                  },
                  {
                    label: t('common.no'),
                    value: 'no',
                  },
                ] }
                defaultValue={ null !== initialConsentsValues[marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT]
                  ? (initialConsentsValues[marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT] ? 'yes' : 'no') : null }
                error={ errorInformativeMaterial }
                helperText={ helperInformativeMaterial }
                validate={ validateInformativeMaterial }
              />
            </Grid>
          </Grid>
        </FormSection>
      </Grid>

      { (null != child) && (
        <Grid
          item
          container
          spacing={ 2 }
          style={ { display: 'flex',
            alignItems: 'flex-end',
            flexDirection: 'column' } }
        >
          <Grid item xs={ 12 }>
            <SimpleCheckbox
              label={ t('reg_kit.consents.child.guardian_consent') }
              name={ generalConsents.LEGAL_GUARDIAN_CONSENT }
              value={ initialConsentsValues[generalConsents.LEGAL_GUARDIAN_CONSENT] }
              onChange={ (v) => {
                setConsentValue(generalConsents.LEGAL_GUARDIAN_CONSENT, v);

                if (null !== child) {
                  validateParentConsent(true === v ? 'yes' : 'no');
                }
              } }
              required={ null !== child }
              error={ parentConsentError }
              helperText={ parentConsentHelperText }
            />
          </Grid>
        </Grid>
      ) }

      <FormSectionSubmit
        label={ t('common.save') }
        loading={ isSubmitting }
        goToPrevPage={ goToPrevPage }
      />
    </form>
  );
};

GeneralConsentWellnessForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

GeneralConsentWellnessForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default GeneralConsentWellnessForm;
