const FormNames = {
  firstName: 'firstName',
  lastName: 'lastName',
  email: 'email',
  gender: 'gender',
  dateOfBirth: 'dateOfBirth',
  streetAddress: 'streetAddress',
  streetNumber: 'street_number',
  postalCode: 'postalCode',
  city: 'city',
  mobileNumber: 'mobileNumber',
  countryMobileNumber: 'countryMobileNumber',
  houseNumber: 'houseNumber',
  countryHouseNumber: 'countryHouseNumber',
  businessNumber: 'businessNumber',
  countryBusinessNumber: 'countryBusinessNumber',
  profession: 'profession',
  specialty: 'specialty',
  country: 'country',
  brandName: 'brandName',
};

export default FormNames;
